import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { COMPONENT } from 'common/constants'

import { commonValues, features, omitKeys } from 'utils/objects'



import InspectRow from './Row'
import SingleNumberControl from './SingleNumberControl'
import SlideControl from './SlideControl'
import { defaultBranding } from "../../../utils/colors";


import { updateObjects, resizeObject } from '../../../ducks/editor/objects'
import { getEditingShape } from 'ducks/editor/shapeEditing'
import SelectControl from './SelectControl'


class InspectBody extends Component {

  handleChange = (value) => {

    const { width, height, depth } = value

    let { objects, updateObjects, resizeObject } = this.props

    let newObjects = objects.map((o) => ({
      ...o,
      ...value,
    }))

    updateObjects(newObjects)

  }

  handleChangeUnit = value => {

    let {objects, updateObjects} = this.props

    let newObjects = objects.map(({id}) => ({...value, id}))

    updateObjects(newObjects)

  }

  getMaxBorderRadius = object => {

    let width = object.width || 100

    let height = object.height || 100

    let min = Math.min(width, height)

    return Math.floor(min / 2)

  }

  render() {

    let { objects, skipFeatures, branding } = this.props

    let objectFeatures = omitKeys(
      commonValues(objects.map(obj => features[obj.type])),
      skipFeatures
    )

    let object = commonValues(objects)

    return (

      <div>

        <InspectRow labeled bindable title="Положение">

          <SingleNumberControl
            label="Положение X"
            name="x"
            value={object.x}
            converted={true}
            suffix = "mm"
            onChange={this.handleChange}
          />

          <SingleNumberControl
            label="Положение Y"
            name="y"
            value={object.y}
            converted={true}
            suffix = "mm"
            onChange={this.handleChange}
          />

          <SingleNumberControl
            disabled={object.type === COMPONENT}
            minValue = {-180}
            maxValue= {180}
            label="Поворот"
            name="angle"
            value={object.angle}
            suffix={"°"}
            onChange={this.handleChange}
          />

        </InspectRow>

        <InspectRow labeled bindable title="Размер">

          <SingleNumberControl
            disabled={object.type === COMPONENT}
            label="Ширины"
            name="width"
            value={object.width}
            converted={true}
            suffix = "mm"
            onChange={this.handleChange}
          />

          <SingleNumberControl
            disabled={object.type === COMPONENT}
            label="Высота"
            name="height"
            value={object.height}
            converted={true}
            suffix = "mm"
            onChange={this.handleChange}
          />

          <SingleNumberControl

            label="Глубина"
            name="depth"
            value={object.depth}
            converted={true}
            suffix = "mm"
            onChange={this.handleChange}
          />

        </InspectRow>

        {objectFeatures.borderRadius && (
          <InspectRow title="Скругления">
            <SlideControl
              name="borderRadius"
              value={object.borderRadius}
              defaultValue={0}
              onChange={this.handleChange}
              min={0}
              max={this.getMaxBorderRadius(object)}
            />
          </InspectRow>
        )}
        
        {object.orientations && (
          <InspectRow title="Ориентация">
            <SelectControl
              name="orient"
              small={false}
              defaultValue={object.orientations[0].id}
              value={object.orient}
              onChange={this.handleChange}
              options={object.orientations.map((m) => ({value: m.id, label:m.name}))}
            />
          </InspectRow>
        )}

      </div>

    )
  }

}

const mapStateToProps = (state) => {

  const branding = defaultBranding

  return {
    branding,
    isEditingShape: getEditingShape(state),
  }

}

export default withRouter(connect(mapStateToProps, {
  updateObjects,
  resizeObject,
})(InspectBody))