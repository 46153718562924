import immutabilityHelper from 'immutability-helper'
import { COMPONENT } from 'common/constants'
import { PathItem } from "utils/vector"
import { CompoundPath } from "utils/vector/classes"

import { remove_children } from "./remove_children"
import { FlowGraphConsoleLogBlock } from 'babylonjs'



const pathLength = (path) => {
  if (!path) {
    return 0
  }
  return path.split('.').length
}

const subPath = (path, length) => {
  return (path || '').split('.').slice(0, length).join('.')
}

const getObject = (objects, path) => {
  if (!objects || !path || path.length === 0) {
    return null
  }

  if (typeof path === 'string') {
    path = path.split('.')
  }

  if (path.length === 1) {
    return objects[path[0]]
  }

  const newObject = objects[path[0]]
  return newObject && getObject(newObject.children, path.slice(1)) || null
}

const unScale = (point, zoom) => {
  if (!point) {
    return point
  }

  let { scale, offset } = zoom

  let [offsetX, offsetY] = offset
  let [x, y] = point

  return [(x - offsetX) / scale, (y - offsetY) / scale]
}

const isCenter = key => {
  return key.match(/center$/)
}

const optimize = (objects, func) => {
  let best = null;
  let bestScore = -Infinity;

  for (let i = 0; i < objects.length; i += 1) {
    const obj = objects[i];
    const score = func(obj);

    if (score > bestScore) {
      best = obj;
      bestScore = score;
    }
  }

  return best;
}

const getSnapValue = (grid, coords, zoom, isResize = false) => {

  let coordValues = Object.keys(coords).map(key => ({
    key,
    value: coords[key],
  }))

  let tolerence = 3.7795275591 / zoom.scale

  let centerGrid = grid.filter(g => g.center)
  let edgeGrid = grid.filter(g => g.edge)

  let candidates = coordValues.map(({ key, value }) => {
    let scaledTolerence = tolerence

    if (isResize && isCenter(key)) {
      scaledTolerence = tolerence / 2
    }

    let grid = isCenter(key) ? centerGrid : edgeGrid

    let snapValue = optimize(grid, ({ point }) => {
      return -Math.abs(point - value)
    })

    if (snapValue && Math.abs(snapValue.point - value) <= scaledTolerence) {
      return { key, value: snapValue.point }
    }

    return undefined
  }).filter(result => result)

  let result = optimize(candidates, ({ key, value }) => {
    let factor = isCenter(key) && isResize ? 2 : 1
    return -Math.abs(value - coords[key]) * factor
  })

  if (result) {
    return {
      [result.key]: result.value,
    }
  }

  return null
}

const internalMove = (obj) => {
  const { compound, points } = obj

  let shape = PathItem.create()
  if (compound && compound.length > 0) {
    shape = PathItem.create(compound)
  } else if (points && points.length > 0) {
    shape = PathItem.create(points)
  }

  if (
    (!shape.segments && !shape.children) ||
    ((shape.segments && !shape.segments.length) && (shape.children && !shape.children.length))
  ) {
    return {
      compound,
      points
    }
  }

  let bounds = shape.bounds

  shape.adjust(obj.x, obj.y)

  let newPoints = []
  let newCompound = []

  if (shape instanceof CompoundPath) {
    newCompound = shape.children.map((path) => {
      return { points: path.points, depth: path.depth }
    })
  } else {
    newPoints = shape.points
  }

  return {
    points: newPoints,
    compound: newCompound,
  }
}

const translateChildren = (group, oldGroup) => {
  if (!group.children || !group.children.length) {
    return group;
  }

  const diffX = group.x - oldGroup.x;
  const diffY = group.y - oldGroup.y;
  const diffA = group.angle - oldGroup.angle;
  const error = group.error !== oldGroup.error;

  if (!diffX && !diffY && !diffA) {
    return group;
  }

  const translated = { ...group };

  translated.children = translated.children.map(function (child) {
    const { compound, points, angle } = child

    return translateChildren({
      ...child,
      ...internalMove({
        ...child,
        x: child.x + diffX,
        y: child.y + diffY,
      }),
      x: child.x + diffX,
      y: child.y + diffY,
      angle: child.angle + diffA,
      error: group.error,
    }, child);
  });

  return translated;
}

const moveShape = (obj, originalObject, diffX, diffY) => {
  return internalMove(obj)
}

const update = (list, path, newObject) => {
  return immutabilityHelper(list, buildUpdate(path, newObject))
}

const buildUpdate = function (path, obj, insert = false) {
  const removeCount = insert ? 0 : 1;

  if (!path) {
    path = [];
  }

  if (typeof path === 'string') {
    path = path.split('.');
  }

  if (path.length === 0) {
    return {};
  }

  if (path.length === 1) {
    const spliceObj = [path[0], removeCount];
    if (obj) {
      spliceObj.push(obj);
    }
    return {
      $splice: [spliceObj]
    };
  }

  return {
    [path[0]]: {
      children: buildUpdate(path.slice(1), obj, insert)
    }
  };
}

const resizeParent = obj => {
  return (
    obj.type !== COMPONENT &&
    (obj.type !== LIST ||
      obj.width === 0 ||
      obj.height === 0 ||
      obj.width === Infinity)
  )
}

const updateParentBounds = (list, map, id, path, shouldUpdate) => {
  path = path || map[id]

  if (!path) {
    return list
  }

  const parentPath = subPath(path, pathLength(path) - 1)

  return updateBounds(list, map, parentPath, shouldUpdate)
}

const removeInfinity = (val, fallback) => {

  if (!isFinite(val)) {
    return fallback
  }

  return val
}

const shallowEqual = (obj1, obj2, fields) => {

  if (!obj1 || !obj2 || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2
  }

  if (!fields) {
    fields = Object.keys({ ...obj1, ...obj2 })
  }

  for (let i = 0; i < fields.length; i += 1) {
    if (obj1[fields[i]] !== obj2[fields[i]]) {
      return false
    }
  }

  return true
}

const updateBounds = function (list, map, path, shouldUpdate = null) {

  const obj = getObject(list, path)

  if (!obj) {
    return list
  }

  if (shouldUpdate && !shouldUpdate(obj)) {
    return list
  }

  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity,
    maxD = obj.depth || 0;

  obj.children.forEach(function (child) {

    if (child.x < minX) {
      minX = child.x
    }

    if (child.x + child.width > maxX) {
      maxX = child.x + child.width
    }

    if (child.y < minY) {
      minY = child.y
    }

    if (child.y + child.height > maxY) {
      maxY = child.y + child.height
    }

    if (child.depth >= maxD) {
      maxD = child.depth
    }

  })

  obj.compound?.forEach(function (child) {
    if (child.depth >= maxD) {
      maxD = child.depth
    }
  })

  const x = removeInfinity(minX, 0)
  const y = removeInfinity(minY, 0)
  const width = removeInfinity(maxX - minX, 0)
  const height = removeInfinity(maxY - minY, 0)

  const updatedObj = {
    ...obj,
    x: x,
    y: y,
    width: width,
    height: height,
    depth: maxD
  }


  const eq = shallowEqual(obj, updatedObj, ['x', 'y', 'width', 'height', 'depth'])

  if (!eq) {
    list = update(list, path, updatedObj)
    const parentPath = subPath(path, pathLength(path) - 1)
    list = updateBounds(list, map, parentPath, shouldUpdate)
  }

  return list;
}



export function computed_drag(state, action) {

  let {
    list,
    map,
    typeIndex,
    zoom,
    xGrid,
    yGrid,
    selection,
    positioningObjects,
    positioningStartPoint,
    positioningConstraint
  } = state

  let { position, shiftPressed } = action

  const template_width = state.list[0].width
  const template_height = state.list[0].height

  positioningObjects = remove_children(positioningObjects, list, map, selection, position, positioningStartPoint)

  let currentXSnap = null; let currentYSnap = null

  if (positioningObjects) {

    let [startX, startY] = unScale(positioningStartPoint, zoom)
    let [newX, newY] = unScale(position, zoom)

    let diffX = Math.round(newX - startX)
    let diffY = Math.round(newY - startY)

    let xCoords = {}; let yCoords = {}

    // Snapping
    for (let id of Object.keys(positioningObjects)) {

      let obj = getObject(list, map[id])

      if (!obj) continue

      let component = getObject(list, subPath(map[id], 1))

      let [originalX, originalY] = positioningObjects[id]

      if (obj?.type !== COMPONENT) {
        originalX += component.x
        originalY += component.y
      }

      xCoords = {
        ...xCoords,
        [`${id}.left`]: originalX + diffX,
        [`${id}.right`]: originalX + obj?.width + diffX,
        [`${id}.center`]: originalX + obj?.width / 2 + diffX,
      }

      yCoords = {
        ...yCoords,
        [`${id}.top`]: originalY + diffY,
        [`${id}.bottom`]: originalY + obj.height + diffY,
        [`${id}.center`]: originalY + obj.height / 2 + diffY,
      }

    }

    let xSnap = getSnapValue(xGrid, xCoords, zoom)
    let ySnap = getSnapValue(yGrid, yCoords, zoom)

    if (xSnap) {
      let key = Object.keys(xSnap)[0]
      currentXSnap = xSnap[key]
      diffX += currentXSnap - xCoords[key]
    }

    if (ySnap) {
      let key = Object.keys(ySnap)[0]
      currentYSnap = ySnap[key]
      diffY += currentYSnap - yCoords[key]
    }

    // Shift
    if (shiftPressed && !positioningConstraint) {
      positioningConstraint = Math.abs(diffX) > Math.abs(diffY) ? 'x' : 'y'
    } else if (!shiftPressed && positioningConstraint) {
      positioningConstraint = null
    }

    if (positioningConstraint === 'x') {
      diffY = 0
      currentYSnap = null
    } else if (positioningConstraint === 'y') {
      diffX = 0
      currentXSnap = null
    }

    for (let id of Object.keys(positioningObjects)) {

      let path = map[id]
      let [originalX, originalY] = positioningObjects[id]
      let originalObject = getObject(list, path)

      if (!originalObject) {
        continue
      }

      let x = originalX + diffX
      let y = originalY + diffY

      // Ограничение по горизонтали в пределах родителя
      x = Math.max(0, Math.min(template_width - originalObject.width, x))

      // Ограничение по вертикали в пределах родителя
      y = Math.max(0, Math.min(template_height - originalObject.height, y))

      let newObject = { ...originalObject, x, y }

      if (newObject.type !== COMPONENT) {
        newObject = translateChildren(newObject, originalObject)
      }

      // Обновление формы при перемещении
      const resized = moveShape(newObject, originalObject, diffX, diffY)

      newObject = {
        ...newObject,
        ...resized
      }

      list = update(list, path, newObject)
      list = updateParentBounds(list, map, id, null, resizeParent)
    }

  }

  return {
    ...state,
    list,
    map,
    typeIndex,
    selection,
    currentXSnap: { coord: currentXSnap },
    currentYSnap: { coord: currentYSnap },
    positioningObjects,
    positioningConstraint,
    textEditing: false,
    shapeEditing: false,
  }
  
}