import React, {Component} from 'react'
import {controlTypes, dataTypes} from 'common/constants'

// utils
import {capitalize} from 'utils/type'

// components
import TextControl from '../TextControl'
import ColorControl from './ColorControl'
import BooleanControl from './BooleanControl'
import InspectRow from '../Row'

import IconRow from './IconRow'
import ImageControl from './ImageControl'
import MenuControl from './MenuControl'

import AutosaveInputControl from './AutosaveInputControl'
import SliderControl from './SliderControl'
import HelpText from './HelpText'

export default class PropControl extends Component {

  handleChange = (val, actionChanges) => {

    let { onChange, namespace, prop } = this.props

    let changeObj = val || {}

    if (namespace) {
      changeObj = { [namespace]: { ...val } }
    }

    onChange(changeObj, actionChanges, prop.global)
  }

  renderControlSub() {

    let { prop, value, objectId } = this.props

    let { name, type } = prop

    if (type === dataTypes.BOOLEAN) {

      return (
        <BooleanControl
          name={name}
          onChange={this.handleChange}
          value={value}
          objectId={objectId}
        />
      )
      
    }

    return (
      <TextControl
        name={name}
        onChange={this.handleChange}
        value={value}
        placedholder={prop.default}
      />
    )
  }

  renderControl() {
    let {
      canBeUploaded,
      prop,
      value,
      values,
      objectId,
      role,
      reference,
      namespace,
    } = this.props

    let { name, displayName, editable } = prop

    if (editable === false) {
      return null
    }

    displayName = displayName || name

    let baseProps = {
      name,
      displayName,
      value,
      values,
      role,
      reference,
      objectId,
      onChange: this.handleChange,
      namespace,
    }

    switch (prop.control && prop.control.type) {
      case controlTypes.MENU:
        return <MenuControl {...baseProps} options={prop.control.options} />
      case controlTypes.SLIDER:
        return (
          <SliderControl
            name={name}
            defaultValue={prop.defaultValue}
            onChange={this.handleChange}
            value={value}
            title={displayName || capitalize(name)}
            max={prop.control.max}
            min={prop.control.min}
          />
        )
    }

    if (prop.role === 'autosaveInput') {
      return <AutosaveInputControl {...baseProps} dataType={prop.type} />
    }

    switch (prop.type) {
      case dataTypes.TEXT: {
        const disableMagicText = prop?.global || prop?.disableDynamicValues
        const libraryEditStyles = prop.styles

        return (
          <EntityTextControl
            {...baseProps}
            disableMagicText={disableMagicText}
            placeholder={prop.placeholder || 'Enter Text...'}
            libraryEditStyles={libraryEditStyles}
          />
        )
      }
      case dataTypes.NUMBER:
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
        return (
          <FormulaControl {...baseProps} placeholder="0" dataType={prop.type} />
        )
      case dataTypes.IMAGE:
        return (
          <ImageControl
            {...baseProps}
            canBeUploaded={canBeUploaded}
            role={role}
            reference={reference}
            objectId={objectId}
            placeholder="Select image..."
          />
        )


      case dataTypes.ICON:
        return <IconRow {...baseProps} />
      case dataTypes.COLOR:
        return (
          <React.Fragment>
            <ColorControl {...baseProps} values={values} />
            <div />
          </React.Fragment>
        )
      default:
        return (
          <InspectRow
            title={displayName || capitalize(name)}
            className="library-inspect-panel-row"
          >
            {this.renderControlSub()}
          </InspectRow>
        )
    }
  }

  render() {
    let {
      prop: { helpText },
    } = this.props

    return (
      <>
        {this.renderControl()}
        {helpText ? <HelpText text={helpText} /> : null}
      </>
    )
  }
}
