import React, {Component} from 'react'
import {connect} from 'react-redux'

import {getScaleRect, scale,} from '../../../utils/zoom'

import {defaultBranding} from '../../../utils/colors'

class Grid extends Component {

  render() {
    let {
      component,
      zoom,
    } = this.props

    let {x, y, width, height} = component

    let [left, top] = scale([x, y], zoom)

    let widthScaled = width
    let heightScaled = height

    let [offsetX, offsetY] = zoom.offset

    const {innerWidth, innerHeight} = window

    const calculatedWidth = Math.min(widthScaled + left, innerWidth)
    const calculatedHeight = Math.min(heightScaled + top, innerHeight)

    let rect = getScaleRect(zoom, true, x, y)

    const ox = offsetX > 0 ? 0 : Math.abs(offsetX)
    const oy = offsetY > 0 ? 0 : Math.abs(offsetY)

    const calculateGridLine = (size1, size2, offset, scale, isVertical = false) => {

      const unit = 1 / 0.2645833333 //mm

      scale = scale || 1

      let decimal = 2;

      let markersDistance = unit * (scale || 1)
      let result = []
      let o = 1
      let divisors = 10;

      if (markersDistance < 30) {
        o = (Math.floor(30 / markersDistance / divisors) + 1) * divisors;
        let t = Math.floor((markersDistance = unit * scale * o) / 30);
        t > 1 && (markersDistance = unit * scale * (o = (Math.round(30 / markersDistance / (divisors = t > 5 ? 2 : 5)) + 1) * divisors))
      }

      let origin = offset - Math.ceil(offset / markersDistance) * markersDistance;
      let f, h, m, g, p = origin, v = markersDistance / divisors;

      for (g = size1; p < g;) {
        let point1, point2
        for (f = .5 + ~~p,
               point1 = isVertical ? [size2, f] : [f, size2],
               point2 = isVertical ? [0, f] : [f, 0],
               result.push([point1, point2, Math.round((p - offset) / markersDistance * o)]),
               m = 1; m < divisors; m++) {
          h = ~~p + v * m + .5

          point1 = isVertical ? [size2, h] : [h, size2]
          point2 = isVertical ? /* m % 2 ? [ 0, h] : */[0, h] : [h, 0],

            result.push([point1, point2, ((p - offset + v * m) / markersDistance * o).toFixed(decimal)])
        }
        p += markersDistance
      }
      return result

    }

    const xGrid = calculateGridLine(calculatedWidth, calculatedHeight, 0, zoom.scale)
    const yGrid = calculateGridLine(height, calculatedWidth, offsetY - Math.max(rect.y, 0), zoom.scale, true)

    //if (zoom.scale <= 0.15) return null


    return (

      <g>

        {xGrid.map(obj => (
          <line
            x1={( obj[0][0]) / zoom.scale} y1={( obj[0][1]) / zoom.scale} value={obj[2]}
            x2={( obj[1][0]) / zoom.scale} y2={( obj[1][1]) / zoom.scale} strokeWidth={1 / (zoom.scale * 10)}
            stroke={'#000'}/>
        ))
        }
        {<rect x={ox} y={ox} width={calculatedWidth} height={calculatedHeight} stroke={"#cf0000"}/>}
        {<rect x={0} y={0} width={100} height={100} stroke={"#ba861e"}/>}
      </g>
        /*{
                  yGrid.map(obj => (
                    <line
                      x1={(ox + obj[0][0]) / zoom.scale} y1={(oy + obj[0][1]) / zoom.scale} value={obj[2]}
                      x2={(ox + obj[1][0]) / zoom.scale} y2={(oy + obj[1][1]) / zoom.scale} strokeWidth={1 / (zoom.scale * 10)}
                      stroke={'#000'}/>
                  ))
                }}*/

    )
  }

}

const mapStateToProps = state => ({
  branding: defaultBranding,
})

export default connect(mapStateToProps)(Grid)
