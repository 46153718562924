import {
  backgroundStyle,
  COMPONENT,
  dataTypes,
  DATE_PICKER,
  ELLIPSE,
  FILE_UPLOAD,
  GROUP,
  IMAGE_UPLOAD,
  INPUT,
  LABEL,
  LIBRARY_COMPONENT,
  LIST,
  RECTANGLE,
  SELECT,
  SHAPE
} from 'common/constants'

import {
  decrementPath,
  deepMerge,
  evaluate,
  getBoundingBox,
  getComponentPaths,
  getDropPath,
  getGroupPath,
  getId,
  getInsertPath,
  getObject,
  getParentPath,
  incrementPath,
  insert,
  isChildPath,
  joinPaths,
  mergeReducers,
  nextPath,
  pathLength,
  remapSiblings,
  remove,
  removeChildren,
  sortPaths,
  subPath,
  translateChildren,
  uniqueElements,
  update,
  updateBounds,
  updateParentBounds,
} from 'common/utils'

import {setGlobalDefaults} from 'utils/library-globals'
import {convertType, defaults, indexByType} from 'utils/objects'

import {getAppComponent, updateComponentBounds} from 'utils/libraries'

import { saveDiffed, saveTouched, setPrevList } from 'utils/saving'


import {createName} from 'utils/naming'
import {updateObjectWidth} from 'utils/text'
import {getSnapGrid} from 'utils/snapping'
import {calculateZoom, getOffset} from 'utils/zoom'
import {updateOptions, updateParentOptions} from 'utils/groupTypes'
import {ADD} from 'utils/tabs'

import selectionReducer, {DEFAULT_SELECTION, getActiveComponent, SELECT_PARENT,} from './selection'


import { SET_TOOL } from './tools'

import {alignToRect, getAbsoluteBbox, getBestParent, getInsertPosition} from '../../utils/geometry'

import textEditingReducer from './textEditing'
import shapeEditingReducer from './shapeEditing'

import positioningReducer from './positioning'
import clipboardReducer from './clipboard'
import snappingReducer, {snappingMiddleware} from './snapping'
import tabsReducer from './tabs'
import {PathItem, Point as PathPoint, Rectangle as PathRectangle, Segment} from "../../utils/vector";
import {createRectangle, flip, updateShapeAngle, updateShapeBounds} from "../../utils/shapes";
import {CompoundPath} from "../../utils/vector/classes";
import {getRandom} from '../../utils/colors'


import { computed_objects } from './objects_action/computed_objects'










export const REQUEST_DATA = Symbol('REQUEST_DATA')
export const SET_DATA = Symbol('SET_DATA')






export const SET_DATA_v2 = Symbol('SET_DATA_v2')
export const EDITOR_INIT_DEFAULT = Symbol('EDITOR_INIT_DEFAULT')






export const CREATE_OBJECT = Symbol('CREATE_OBJECT')
export const RESET_OBJECTS = Symbol('RESET_OBJECTS')
export const UPDATE_OBJECT = Symbol('UPDATE_OBJECT')


export const UPDATE_OBJECTS = Symbol('UPDATE_OBJECTS')


export const CHANGE_OBJECT_TYPE = Symbol('CHANGE_OBJECT_TYPE')
export const RESIZE_OBJECT = Symbol('RESIZE_OBJECT')
export const POSITION_OBJECTS = Symbol('POSITION_OBJECTS')
export const DELETE_OBJECT = Symbol('DELETE_OBJECT')
export const REORDER_OBJECTS = Symbol('REORDER_OBJECTS')
export const REORDER_OBJECTS_MOVE_LAST = Symbol('REORDER_OBJECTS_MOVE_LAST')
export const REORDER_OBJECTS_MOVE_FIRST = Symbol('REORDER_OBJECTS_MOVE_FIRST')
export const REORDER_OBJECTS_MOVE_UP = Symbol('REORDER_OBJECTS_MOVE_UP')
export const REORDER_OBJECTS_MOVE_DOWN = Symbol('REORDER_OBJECTS_MOVE_DOWN')
export const GROUP_OBJECTS = Symbol('GROUP_OBJECTS')
export const GROUP_OBJECTS_TO_LIST = Symbol('GROUP_OBJECTS_TO_LIST')
export const UNITE_OBJECTS = Symbol('UNITE_OBJECTS')
export const INTERSECT_OBJECTS = Symbol('INTERSECT_OBJECTS')
export const SUBTRACT_OBJECTS = Symbol('SUBTRACT_OBJECTS')
export const EXCLUDE_OBJECTS = Symbol('EXCLUDE_OBJECTS')
export const DIVIDE_OBJECTS = Symbol('DIVIDE_OBJECTS')
export const UNGROUP_OBJECTS = Symbol('UNGROUP_OBJECTS')
export const SET_PAGE_SIZE = Symbol('SET_PAGE_SIZE')
export const ALIGN_OBJECTS = Symbol('ALIGN_OBJECTS')
export const FLIP_OBJECTS = Symbol('FLIP_OBJECTS')
const ZOOM = Symbol('ZOOM')
const RESET_ZOOM = Symbol('RESET_ZOOM')
const PAN = Symbol('PAN')
const SET_LIBRARY_GLOBAL = Symbol('SET_LIBRARY_GLOBAL')







const INITIAL_STATE = {
  appId: null,
  loading: false,
  name: null,
  zoom: {
    scale: 1,
    offset: [0, 0],
  },
  map: {},
  parentMap: {},
  list: [],

  // Indices map to components at the top level
  // typeIndex: { COMPONENT1: { TYPE: [...] } }
  // Others: { COMPONENT1: [...] }
  typeIndex: {},
  selection: DEFAULT_SELECTION,
  hoverSelection: DEFAULT_SELECTION,
  activeComponent: null,
  textEditing: null,
  shapeEditing: null,
  draggingOutControl: false,
  draggingInControl: false,
  selectedPoint: null,
  draggingSelectedPoint: false,
  zoomAppId: null,
  dragging: false,
  panning: false,
  xGrid: null,
  yGrid: null,
  currentXSnap: null,
  currentYSnap: null,
  positioningObjects: null,
  positioningStartPoint: null,
  positioningConstraint: null,
  activeTab: null,
  sketchUpload: {
    uploadInProgress: false,
    uploadError: null,
    progress: null,
  },
  selectedParent: null,
  libraryGlobals: {},
}




const emptyObject = (obj, list, setId = true) => {
  
  let id = setId ? getId() : obj.id

  let newObj = {
    ...defaults[obj.type],
    ...obj,
    name: createName(obj.type, obj.name, list),
    id,
    backgroundStyle: backgroundStyle.COLOR,
    backgroundColor: getRandom(obj.backgroundColor),
  }

  for (const symbol of Object.getOwnPropertySymbols(newObj)) {
    delete newObj[symbol]
  }

  if (newObj.type !== GROUP && newObj.type !== COMPONENT) {
    newObj.x = newObj.x || 0
    newObj.y = newObj.y || 0
  }

  return newObj
}

const getComponentObject = (components, id) => {
  let componentObj = components[id]

  let {
    x,
    y,
    width,
    height,
    depth,
    objects,
    order,
    name,
    backgroundColor,
    backgroundImage,
    backgroundPositionX,
    backgroundPositionY,
    backgroundSize,
    statusBarStyle,
    reverseScroll,
    reusableComponent,
    onVisit,
    componentActions,
    screenTemplate,
  } = componentObj

  return {
    id,
    name,
    backgroundColor,
    backgroundImage,
    backgroundPositionX,
    backgroundPositionY,
    backgroundSize,
    onVisit,
    componentActions,
    type: COMPONENT,
    x: x || 0,
    y: y || 0,
    width,
    height,
    depth,
    order,
    reusableComponent,
    statusBarStyle,
    reverseScroll,
    screenTemplate,
    children: objects,
  }
}

export const resizeParent = obj => {
  return (
    obj.type !== COMPONENT &&
    (obj.type !== LIST ||
      obj.width === 0 ||
      obj.height === 0 ||
      obj.width === Infinity)
  )
}

export const getParentId = (list, map, typeIndex, newObject) => {
  
  let listIds = []

  list.forEach(component => {
    listIds = listIds.concat((typeIndex[component.id] || {})[LIST] || [])
  })

  listIds = listIds.filter(
    listId =>
      listId !== newObject.id &&
      (!map[newObject.id] || !isChildPath(map[newObject.id] || '', map[listId]))
  )

  let parentId = getBestParent(
    newObject,
    list.concat(
      ...listIds.map(id => {
        return getAbsoluteBbox(getObject(list, map[id]), list, map)
      })
    )
  )

  return parentId
}



const innerPerformCreate = (
  object,
  list,
  objects,
  id,
  newIds,
  state,
  parentId,
  map,
  path,
  isCopy,
  isRelative,
  setSelection,
  zoom,
  selection,
  objectIds,
  newObjects,
  changeIds = true
) => {
  
  // Not sure why eslint doesn't like this...it is used below
  // eslint-disable-next-line no-unused-vars
  let componentId

  let newObject = emptyObject(object, list, changeIds)

  if (objects.length === 1 && id) {
    newObject.id = id
  }

  newIds.push(newObject.id)

  if (!parentId) {
    parentId = getParentId(list, map, state.typeIndex, newObject)
  }

  let newPath = getInsertPath(list, path, map[parentId])

  if (isCopy && path) {
    newPath = path; path = nextPath(path)
  }

  if (newObject.type === COMPONENT && pathLength(newPath) > 1) {
    newPath = nextPath(subPath(newPath, 1))
  }
  
  if (parentId) {

    let component = getObject(list, subPath(map[parentId], 1))

    if (!isRelative) {
      newObject.x -= component.x; newObject.y -= component.y
    }

    const {compound, points} = newObject

    if ((compound && compound.length > 0) || (points && points.length > 0)) {

      let p = PathItem.create()
      if (compound && compound.length > 0) {
        p = PathItem.create(compound)
      } else if (points && points.length > 0) {
        p = PathItem.create(points)
      }

      p.adjust(newObject.x, newObject.y)
      
      let newPoints = []
      let newCompound = []

      if (p instanceof CompoundPath) {
        newCompound = p.children.map((path) => {
          return {points: path.points, depth: path.depth}
        })
      } else {
        newPoints = p.points
      }

      newObject = {
        ...newObject,
        compound :newCompound,
        points : newPoints,
      }

    }

    newObject.depth =  newObject.depth || component.depth / 2

  } else {

    // Get out of here if trying to insert non-component at top-level
    if (newObject.type !== COMPONENT) {
      return null
    }

    if (!isRelative && !(newObject.x !== undefined || newObject.y !== undefined)) {
      let position = getInsertPosition(list)
      newObject = {...newObject, ...position}
    }

    if (setSelection) {
      zoom = calculateZoom(newObject)
    }

    componentId = newObject.id
  }


  newObject = updateObjectWidth(newObject)
  list      = insert(list, newPath, newObject)
  map       = remapSiblings(list, map, newPath)
  list      = updateParentBounds(list, map, newObject.id, null, resizeParent)
  list      = updateOptions(list, map, newObject.id)



  if (newObject.type !== COMPONENT) {
    selection.push(newObject.id)
  }

  if (newObject.type === COMPONENT && newObject.objects && newObject.objects.length > 0) {

    const children = newObject.objects

    newObject.objects = []

    children.forEach(child => {
      const result = innerPerformCreate(
        child,
        list,
        [],
        null,
        newIds,
        state,
        newObject.id,
        map,
        path,
        isCopy,
        true,
        setSelection,
        zoom,
        [],
        objectIds,
        newObjects,
        changeIds
        )

      ;({list, map} = result)
    })

  }

  if (newObject.type === GROUP && (newObject.children && newObject.children.length > 0)) {

    const children = newObject.children; newObject.children = []

    children.forEach(child => {

      child = {
        ...child,
        x: newObject.x + child.x,
        y: newObject.y + child.y,
        id: getId(),
      }

      const result = innerPerformCreate(
        child,
        list,
        [],
        null,
        newIds,
        state,
        newObject.id,
        map,
        path,
        isCopy,
        true,
        setSelection,
        zoom,
        [],
        objectIds,
        newObjects,
        changeIds
      )

      ;({ list, map } = result)

    })

  }

  objectIds.push(newObject.id); newObjects.push(newObject)

  return { list, parentId, map, path, zoom }

}








export const performCreate = (
  state,
  path,
  objects,
  id,
  parentId,
  isRelative = false,
  isCopy = false,
  setSelection = true,
  changeIds = true
) => {

  let {list, map, zoom} = state
  let objectIds = []
  let selection = []
  let newObjects = []
  let newIds = []

  if (!Array.isArray(objects)) {
    objects = [objects]
  }

  if (!parentId && state.selectedParent) {
    parentId = state.selectedParent
  }

  state = {
    ...state,
    selectedParent: null,
  }

  for (let object of objects) {

    const result = innerPerformCreate(
      object,
      list,
      objects,
      id,
      newIds,
      state,
      parentId,
      map,
      path,
      isCopy,
      isRelative,
      setSelection,
      zoom,
      selection,
      objectIds,
      newObjects,
      changeIds
    )

    if (result == null) {
      // Get out of here if trying to insert non-component at top-level
      return state
    }

    ;({list, map, path, zoom} = result)

  }

  let libraryGlobals = setGlobalDefaults(state.libraryGlobals, newObjects)

  saveTouched(
    state.appId,
    list,
    map,
    newObjects.map(o => o.id),
    undefined,
    undefined,
    libraryGlobals
  )

  let newObject = newObjects.length === 1 ? newObjects[0] : {}

  return [
    updateIndices({
      ...state,
      list,
      map,
      zoom,
      libraryGlobals,
      selection: setSelection ? selection : state.selection,
      activeComponent: getActiveComponent(list, map, selection),
      shapeEditing: (newObject.type === SHAPE || newObject.type === ELLIPSE)  && newObject.id,
    }),
    ...newIds,
  ]

}

export const performUpdate = (
  state,
  path,
  objects,
  id,
  parentId,
  isRelative = false,
  isCopy = false,
  setSelection = true,
  changeIds = true
) => {


  if (!Array.isArray(id)) {
    id = [id]
  }

  let {list, map, zoom} = state
  let paths = []
  let componentIds = []
  let parentPath = ""
  id.forEach(id => {
    let path = map[id]
    let obj = getObject(list, path)

    if (obj.type === COMPONENT) {
      componentIds.push(id)
    } else {
      paths.push(path)
    }

    list = remove(list, path)

    list = updateParentBounds(list, map, id, null, resizeParent)
    list = updateParentOptions(list, map, id)

    map = {...map}
    delete map[id]
    map = remapSiblings(list, map, subPath(path, path.length - 1))

    let pieces = path.split('.')
    parentPath = pieces.slice(0, pieces.length - 1).join('.')
    let siblings = []

    if (parentPath === '') {
      siblings = list
    } else {
      let parentObj = getObject(list, parentPath)

      siblings = (parentObj && parentObj.children) || []
    }

    let pathPrefix = parentPath === '' ? '' : `${parentPath}.`

    siblings.forEach((sibling, position) => {
      map[sibling.id] = `${pathPrefix}${position}`
    })
  })

  saveTouched(state.appId, list, map, null, paths, componentIds)

  updateIndices({
    ...state,
    list,
    map,
    selection: [],
    hoverSelection: [],
    shapeEditing: null,
  })


  let objectIds = []
  let selection = []
  let newObjects = []
  let newIds = []

  if (!Array.isArray(objects)) {
    objects = [objects]
  }

  if (!parentId && state.selectedParent) {
    parentId = state.selectedParent
  }

  state = {
    ...state,
    selectedParent: null,
  }

  for (let object of objects) {
    const result = innerPerformCreate(
      object,
      list,
      objects,
      id,
      newIds,
      state,
      parentId,
      map,
      parentPath,
      isCopy,
      isRelative,
      setSelection,
      zoom,
      selection,
      objectIds,
      newObjects,
      changeIds
    )

    if (result == null) {
      // Get out of here if trying to insert non-component at top-level
      return state
    }

    ;({list, map, path, zoom} = result)
  }

  let libraryGlobals = setGlobalDefaults(state.libraryGlobals, newObjects)

  saveTouched(
    state.appId,
    list,
    map,
    newObjects.map(o => o.id),
    undefined,
    undefined,
    libraryGlobals
  )

  let newObject = newObjects.length === 1 ? newObjects[0] : {}

  return [
    updateIndices({
      ...state,
      list,
      map,
      zoom,
      libraryGlobals,
      selection: setSelection ? selection : state.selection,
      activeComponent: getActiveComponent(list, map, selection),
      shapeEditing: (newObject.type === SHAPE || newObject.type === ELLIPSE)  && newObject.id,
    }),
    ...newIds,
  ]
}

export const performDelete = (state, id) => {
  if (!Array.isArray(id)) {
    id = [id]
  }

  let list = state.list
  let map = state.map
  let paths = []
  let componentIds = []

  id.forEach(id => {
    let path = map[id]
    let obj = getObject(list, path)

    if (obj.type === COMPONENT) {
      componentIds.push(id)
    } else {
      paths.push(path)
    }

    list = remove(list, path)

    list = updateParentBounds(list, map, id, null, resizeParent)
    list = updateParentOptions(list, map, id)

    map = {...map}
    delete map[id]
    map = remapSiblings(list, map, subPath(path, path.length - 1))

    let pieces = path.split('.')
    let parentPath = pieces.slice(0, pieces.length - 1).join('.')
    let siblings = []

    if (parentPath === '') {
      siblings = list
    } else {
      let parentObj = getObject(list, parentPath)

      siblings = (parentObj && parentObj.children) || []
    }

    let pathPrefix = parentPath === '' ? '' : `${parentPath}.`

    siblings.forEach((sibling, position) => {
      map[sibling.id] = `${pathPrefix}${position}`
    })
  })

  saveTouched(state.appId, list, map, null, paths, componentIds)

  return updateIndices({
    ...state,
    list,
    map,
    selection: [],
    hoverSelection: [],
    shapeEditing: null,
  })
}

export const performReset = (  state,
                               path,
                               objects,
                               id,
                               parentId,
                               isRelative = false,
                               isCopy = false,
                               setSelection = true,
                               changeIds = true) => {

  let list = []
  let map = {}
  let paths = []
  let componentIds = []

  let {zoom} = state
  let objectIds = []
  let selection = []
  let newObjects = []
  let newIds = []

  if (!Array.isArray(objects)) {
    objects = [objects]
  }


  state = {
    ...state,
    selectedParent: null,
  }

  for (let object of objects) {
    const result = innerPerformCreate(
      object,
      list,
      objects,
      id,
      newIds,
      state,
      parentId,
      map,
      path,
      isCopy,
      isRelative,
      setSelection,
      zoom,
      selection,
      objectIds,
      newObjects,
      changeIds
    )

    if (result == null) {
      // Get out of here if trying to insert non-component at top-level
      return state
    }

    ;({list, map, path, zoom} = result)
  }

  let libraryGlobals = setGlobalDefaults(state.libraryGlobals, newObjects)

  saveTouched(
    state.appId,
    list,
    map,
    newObjects.map(o => o.id),
    undefined,
    undefined,
    libraryGlobals
  )

  let newObject = newObjects.length === 1 ? newObjects[0] : {}

  return [
    updateIndices({
      ...state,
      list,
      map,
      zoom,
      libraryGlobals,
      selection: setSelection ? selection : state.selection,
      activeComponent: getActiveComponent(list, map, selection),
      shapeEditing: (newObject.type === SHAPE || newObject.type === ELLIPSE)  && newObject.id,
    }),
    ...newIds,
  ]

}

const updateIndices = (state, paths = null) => {

  let componentPaths = Object.keys(state.list)

  if (paths) {
    componentPaths = getComponentPaths(paths.map(id => state.map[id]))
  }

  let typeIndex = {...state.typeIndex}

  componentPaths.forEach(path => {
    let component = state.list[path]

    typeIndex[component.id] = indexByType(component.children)
  })

  return {
    ...state,
    typeIndex,
  }
}

// REDUCER

export default mergeReducers(
  INITIAL_STATE,
  [snappingMiddleware],
  selectionReducer,
  textEditingReducer,
  shapeEditingReducer,

  positioningReducer,
  clipboardReducer,
  snappingReducer,
  tabsReducer,

  (state, action) => {

    if (action.type === REQUEST_DATA) {
      let {appId} = action

      // Make call to API
      //requestApp(appId)

      if (appId === state.appId) {
        return {...state, loading: true}
      }

      setPrevList(null)

      return {
        ...INITIAL_STATE,
        activeTab: state.activeTab,
        appId,
        loading: true,
      }
    }


    if (action.type === EDITOR_INIT_DEFAULT) {

      const { data } = action;

      return data;
    }
    

    if (action.type === SET_DATA_v2) {

      const { appId, data, app } = action;
      const activeTab = state.activeTab || ADD;
    
      // Получение объекта компонента
      const getComponentObject = (components, id) => {
        const componentObj = components[id];
    
        const {
          x = 0,
          y = 0,
          width,
          height,
          depth,
          objects,
          order,
          name,
          backgroundColor,
          backgroundImage,
          backgroundPositionX,
          backgroundPositionY,
          backgroundSize,
          statusBarStyle,
          reverseScroll,
          reusableComponent,
          onVisit,
          componentActions,
          screenTemplate,
        } = componentObj;
    
        return {
          id,
          name,
          backgroundColor,
          backgroundImage,
          backgroundPositionX,
          backgroundPositionY,
          backgroundSize,
          onVisit,
          componentActions,
          type: COMPONENT,
          x,
          y,
          width,
          height,
          depth,
          order,
          reusableComponent,
          statusBarStyle,
          reverseScroll,
          screenTemplate,
          children: objects,
        };
      };
    
      const list = Object.keys(data).map(id => getComponentObject(data, id));
      const libraryGlobals = app.libraryGlobals || {};
    
      // Переименование siblings
      const remapSiblings = (list, map = {}, path = '0') => {

        const _extends = Object.assign || ((target, ...sources) => {
          sources.forEach(source => {
            if (source) {
              Object.keys(source).forEach(key => {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                  target[key] = source[key];
                }
              });
            }
          });
          return target;
        });
    
        const getParentPath = (path) => {
          if (!path) return null;
          const pieces = path.split('.');
          return pieces.slice(0, pieces.length - 1).join('.') || null;
        };
    
        const getSiblings = (list, path) => {
          const parentPath = getParentPath(path);
          if (parentPath === null) return list;
          const parentObj = getObject(list, parentPath);
          return parentObj && parentObj.children || [];
        };
    
        const joinPaths = (...paths) => paths.filter(Boolean).join('.');
    
        const remap = (list, map = {}, path = '0') => {
          map = _extends({}, map);
          const parentPath = getParentPath(path);
          const siblings = getSiblings(list, path);
    
          siblings.forEach((obj, position) => {
            const currentPath = joinPaths(parentPath, `${position}`);
            map[obj.id] = currentPath;
    
            if (obj.children) {
              const childrenPath = joinPaths(currentPath, '0');
              const childrenMap = remap(list, {}, childrenPath);
              map = _extends({}, map, childrenMap);
            }
          });
    
          return map;
        };
    
        return remap(list, map, path);
      };
    
      const map = remapSiblings(list, {}, '0');
    
      let zoom = state.zoom;
    
      if (state.zoomAppId !== appId) {
        
        // Вычисление ограничивающего прямоугольника
        const getBoundingBoxV2 = (objects) => {
          if (objects.length === 0) return null;
    
          let x1 = Infinity, x2 = -Infinity, y1 = Infinity, y2 = -Infinity, depth = -Infinity;
    
          objects.forEach((obj) => {
            if (obj.x < x1) x1 = obj.x;
            if (obj.x + obj.width > x2) x2 = obj.x + obj.width;
            if (obj.y < y1) y1 = obj.y;
            if (obj.y + obj.height > y2) y2 = obj.y + obj.height;
            if (obj.depth > depth) depth = obj.depth;
          });
    
          const width = x2 - x1;
          const height = y2 - y1;
    
          return {
            x: x1 === Infinity ? 0 : x1,
            y: y1 === Infinity ? 0 : y1,
            width: width === -Infinity ? 0 : width,
            height: height === -Infinity ? 0 : height,
            depth: depth === -Infinity ? 0 : depth,
          };
        };
    
        // Вычисление зума
        const calculateZoomV2 = (bbox) => {
          if (!bbox) return DEFAULT_ZOOM;
    
          const { width, height } = bbox;
          if (width === 0 || height === 0) return DEFAULT_ZOOM;
    
          const margin = 100;
          const leftPanel = 64;
          const rightPanel = 0;
          const topPanel = 64;
    
          const canvasWidth = window.innerWidth - leftPanel - rightPanel;
          const canvasHeight = window.innerHeight - topPanel / 2;
    
          const xScale = Math.max(0, (canvasWidth - 2 * margin) / width);
          const yScale = Math.max(0, (canvasHeight - 2 * margin) / height);
          let scale = Math.min(xScale, yScale);
    
          const xOffset = (window.innerWidth - leftPanel) / 2 - (width / 2 + bbox.x) * scale;
          const yOffset = window.innerHeight / 2 - (height / 2 + bbox.y) * scale + topPanel / 2;
    
          scale = Math.min(scale, 16);
    
          return {
            scale,
            offset: [xOffset, yOffset],
          };
        };
    
        zoom = calculateZoomV2(getBoundingBoxV2(list));
      }
    
      setPrevList(list);
    
      // Обновление индексов
      const updateIndices = (state, paths = null) => {
        let componentPaths = Object.keys(state.list);
    
        if (paths) {
          const getComponentPaths = (paths) => {
            const subPath = (path, length) => (path || '').split('.').slice(0, length).join('.');
    
            const sortPaths = (paths) => Array.from(paths).sort(comparePaths);
    
            return sortPaths([...new Set(paths.map(p => subPath(p, 1)))]);
          };
    
          componentPaths = getComponentPaths(paths.map(id => state.map[id]));
        }
    
        const typeIndex = { ...state.typeIndex };
    
        const indexByTypeV2 = (objects) => {
          const result = {};
    
          const traverse = (objects, func, traverseChildren, parentObj) => {
            for (let i = 0; i < objects.length; i += 1) {
              const obj = objects[i];
              func(obj, parentObj, objects[i - 1], objects[i + 1]);
    
              if (obj && obj.children && (!traverseChildren || traverseChildren(obj))) {
                if (!Array.isArray(obj.children)) {
                  throw new Error('obj.children is not an Array: ' + JSON.stringify(obj));
                }
    
                traverse(obj.children, func, traverseChildren, obj);
              }
            }
          };
    
          traverse(objects, obj => {
            const { id, type } = obj;
            if (!result[type]) {
              result[type] = [];
            }
            result[type].push(id);
          });
    
          return result;
        };
    
        componentPaths.forEach(path => {
          const component = state.list[path];
          typeIndex[component.id] = indexByTypeV2(component.children || []);
        });
    
        return {
          ...state,
          typeIndex,
        };
      };
    
      const newState = updateIndices({
        ...state,
        appId,
        list,
        map,
        zoom,
        activeTab,
        libraryGlobals,
        loading: false,
      });
    
      // Получение сетки привязки
      const getSnapGridV2 = (state, selection) => {
        const getGroupPath = (paths) => {
          const pathLength = (path) => (path ? path.split('.').length : 0);
          const subPath = (path, length) => (path || '').split('.').slice(0, length).join('.');
    
          const sortPaths = (paths) => {
            const sortedPaths = Array.from(paths).sort(comparePaths);
            return sortedPaths;
          };
    
          if (paths.length === 0) return null;
    
          let commonPath = paths[0];
          paths.forEach((path) => {
            for (let l = pathLength(commonPath); l >= 0; l -= 1) {
              if (subPath(path, l) === subPath(commonPath, l)) {
                commonPath = subPath(commonPath, l);
                break;
              }
            }
          });
    
          paths = sortPaths(paths);
          return paths[0].split('.').slice(0, commonPath.length + 1).join('.');
        };
    
        const selectionPath = getGroupPath(selection.map(id => state.map[id]));
    
        const createSnapGrid = (list, selectionPath = '', guides = false) => {
          const pathLength = (path) => (path ? path.split('.').length : 0);
          const subPath = (path, length) => (path || '').split('.').slice(0, length).join('.');
    
          const getObject = (objects, path) => {
            if (!objects || !path || path.length === 0) return null;
    
            const pathArray = typeof path === 'string' ? path.split('.') : path;
    
            if (pathArray.length === 1) return objects[pathArray[0]];
    
            const newObject = objects[pathArray[0]];
            return newObject && getObject(newObject.children, pathArray.slice(1)) || null;
          };
    
          const getAbsoluteBbox = (object, list, map) => {
            const path = map?.[object?.id];
            if (!path) return object;
    
            const componentPath = subPath(path, 1);
            if (componentPath === path) return object;
    
            const component = getObject(list, componentPath);
            return {
              id: object.id,
              x: object.x + component.x,
              y: object.y + component.y,
              width: object.width,
              height: object.height,
            };
          };
    
          const addPoints = (pointsMap, coords, obj, center = false) => {
            coords.forEach((coord) => {
              if (!pointsMap[coord]) {
                pointsMap[coord] = { objects: [] };
              }
              pointsMap[coord][center ? 'center' : 'edge'] = true;
              pointsMap[coord].objects.push(obj);
            });
          };
    
          const sort = (arr, keyFunc = itm => itm) => {
            return arr.slice().sort((a, b) => {
              const aKey = keyFunc(a);
              const bKey = keyFunc(b);
              return aKey < bKey ? -1 : (bKey < aKey ? 1 : 0);
            });
          };
    
          const xPoints = {};
          const yPoints = {};
          let objects = list.slice();
    
          if (guides) {
            const guidesArray = objects.filter(obj => obj.width > 32 && obj.height > 36).map(obj => ({
              id: obj.id,
              x: obj.x + 16,
              y: obj.y + 20,
              width: obj.width - 32,
              height: obj.height - 36,
            }));
            objects = objects.concat(guidesArray);
          }
    
          // Iterate through depths of selection
          for (let i = 1; i < pathLength(selectionPath); i += 1) {
            const parentPath = subPath(selectionPath, i);
            const parent = getObject(list, parentPath);
    
            // Iterate through siblings at this depth
            if (parent?.children) {
              parent.children.forEach((child, j) => {
                const map = { [child.id]: `${parentPath}.${j}` };
                const absoluteChild = getAbsoluteBbox(child, list, map);
                objects.push(absoluteChild);
              });
            }
          }
    
          objects.forEach((obj) => {
            const xCoords = [Math.round(obj.x), Math.round(obj.x + obj.width)];
            const yCoords = [Math.round(obj.y), Math.round(obj.y + obj.height)];
    
            addPoints(xPoints, xCoords, obj);
            addPoints(yPoints, yCoords, obj);
    
            const centerX = Math.round(obj.x) + Math.round(obj.width) / 2;
            const centerY = Math.round(obj.y) + Math.round(obj.height) / 2;
    
            // Centers
            addPoints(xPoints, [centerX], obj, true);
            addPoints(yPoints, [centerY], obj, true);
          });
    
          const xGrid = Object.keys(xPoints).map(x => ({
            ...xPoints[x],
            point: +x,
          }));
    
          const yGrid = Object.keys(yPoints).map(y => ({
            ...yPoints[y],
            point: +y,
          }));
    
          return {
            xGrid: sort(xGrid, ({ point }) => point),
            yGrid: sort(yGrid, ({ point }) => point),
          };
        };
    
        return createSnapGrid(state.list, selectionPath || '', true);
      };
    
      const updatedState = {
        ...newState,
        ...getSnapGridV2(newState, []),
      };
    
      return updatedState;
    }
    

    if (action.type === SET_DATA) {

      let { appId, data, app } = action

      let activeTab             = state.activeTab || ADD

      let list                  = Object.keys(data).map(id => getComponentObject(data, id))
      let libraryGlobals        = app.libraryGlobals || {}
      let map                   = remapSiblings(list, {}, '0')

      let zoom                  = state.zoom

      if (state.zoomAppId !== appId) {
        zoom = calculateZoom(getBoundingBox(list))
      }

      setPrevList(list)

      let newState = updateIndices({
        ...state,
        appId,
        list,
        map,
        zoom,
        activeTab,
        libraryGlobals,
        loading: false,
      })

      newState = {
        ...newState,
        ...getSnapGrid(newState, []),
      }

      return newState
    }
    


    if (action.type === CREATE_OBJECT) {

      let {path, object, id, silent} = action

      return performCreate(
        state,
        path,
        object,
        id,
        null,
        false,
        false,
        !silent
      )[0]

    }

    if (action.type === RESET_OBJECTS) {
      let {path, object, id, silent} = action

      return performReset(state, path, object, id)[0]

    }





  
    // RESIZE
    if ([UPDATE_OBJECT, RESIZE_OBJECT].indexOf(action.type) !== -1) {

      let { libraryGlobals }        = state
      let { id, object, skipSave }  = action
      let path                      = state.map[id]
      const oldObject               = getObject(state.list, path) || {}
      let changes                   = evaluate(object, oldObject)
      let newObject                 = deepMerge(oldObject, changes)

      if (oldObject.type !== COMPONENT) {
        newObject = updateObjectWidth(newObject)
        newObject = translateChildren(newObject, oldObject)
      }

      if (oldObject.type === LIBRARY_COMPONENT) {

        let {libraryName, componentName} = newObject

        let globals = libraryGlobals[libraryName]?.[componentName]
        newObject = updateComponentBounds(newObject, globals)

        newObject.libraryComponentManifest = getAppComponent(
          null,
          libraryName,
          componentName
        )

      }

      if ((oldObject.type === RECTANGLE) && action.type === UPDATE_OBJECT) {

        const rect = createRectangle(newObject)
          newObject = {
            ...newObject,
            points: rect.points
        }

      }

      if ((oldObject.type === SHAPE || oldObject.type === ELLIPSE || oldObject.type === RECTANGLE) && action.type === RESIZE_OBJECT) {

        const resized = updateShapeBounds(oldObject, changes)

        newObject = {
          ...newObject,
          ...resized
        }

      }


      let list = update(state.list, path, newObject)
      list = updateParentBounds(list, state.map, id, null, resizeParent)
      list = updateOptions(list, state.map, id)

      if (!skipSave) {
        saveTouched(state.appId, list, state.map, [id])
      }

      return {
        ...state,
        list,
      }

    }



    // UPDATE
    if (action.type === UPDATE_OBJECTS) {

      const result = computed_objects(state, action)

      return result

    }


    if (action.type === CHANGE_OBJECT_TYPE) {
      let {id, newType} = action

      let path = state.map[id]
      let object = getObject(state.list, path)
      let {selection, map} = state


      object = convertType(object, newType)


      let list = update(state.list, path, object)
      map = remapSiblings(list, map, '0')

      if (object.id !== id) {
        list = updateParentBounds(
          list,
          map,
          object.children[0].id,
          null,
          resizeParent
        )
      }

      saveTouched(state.appId, list, map, [id])

      return updateIndices({
        ...state,
        selection,
        map,
        list,
      })
    }

    if (action.type === POSITION_OBJECTS) {
      let {ids, offset, shouldSave} = action

      let objects = getSelectedSub(state, ids)

      let list = state.list

      objects.forEach(obj => {
        let newObj = {
          ...obj,
          x: obj.x + offset.x,
          y: obj.y + offset.y,
        }

        if (obj.type !== COMPONENT) {
          newObj = translateChildren(newObj, obj)
        }

        list = update(list, state.map[obj.id], newObj)
        list = updateParentBounds(list, state.map, obj.id, null, resizeParent)
      })

      if (shouldSave) {
        saveTouched(state.appId, list, state.map, ids)
      }

      return {
        ...state,
        list,
      }
    }

    if (action.type === ALIGN_OBJECTS) {
      
      let {selection, appId, list, map} = state
      let {direction} = action
      let objects = selection.map(id => getObject(list, map[id]))
      let absoluteObjects = objects.map(o => getAbsoluteBbox(o, list, map))

      let bbox =
        objects.length === 1
          ? getObject(list, subPath(map[objects[0].id], 1))
          : getBoundingBox(absoluteObjects)

      objects.forEach((obj, i) => {
        let newObj = alignToRect(obj, absoluteObjects[i], bbox, direction)

        if (newObj.type !== COMPONENT) {
          newObj = translateChildren(newObj, obj)
        }

        list = update(list, map[obj.id], newObj)
        list = updateParentBounds(list, map, obj.id, null, resizeParent)
      })

      saveTouched(appId, list, map, selection)

      return {
        ...state,
        list,
      }
    }

    if (action.type === FLIP_OBJECTS) {

      let {selection, appId, list, map} = state
      let {direction} = action
      let objects = selection.map(id => getObject(list, map[id]))
      let absoluteObjects = objects.map(o => getAbsoluteBbox(o, list, map))


      objects.forEach((obj, i) => {
        let newObj = flip(obj,  direction)

        if (newObj.type !== COMPONENT) {
          newObj = translateChildren(newObj, obj)
        }

        list = update(list, map[obj.id], newObj)
        list = updateParentBounds(list, map, obj.id, null, resizeParent)
      })

      saveTouched(appId, list, map, selection)

      return {
        ...state,
        list,
      }
    }

    if (action.type === DELETE_OBJECT) {
      let {id} = action

      return performDelete(state, id)
    }

    if (action.type === REORDER_OBJECTS) {
      let {ids, dropTarget, options} = action
      let {list, map} = state

      let {dropAfter, dropInside} = options

      let dropPath = map[dropTarget]

      if (dropInside) {
        let children = getObject(list, dropPath).children

        if (!children) {
          list = update(list, dropPath, {
            ...getObject(list, dropPath),
            children: [],
          })
        }

        let childCount = children ? children.length : 0
        dropPath = joinPaths(dropPath, `${childCount}`)
      }

      if (!dropAfter) {
        dropPath = incrementPath(dropPath)
      }

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        let path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      let items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(list, map, null, path, resizeParent)
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(list, map, itm.id, null, resizeParent)
        list = updateOptions(list, map, itm.id)
      })

      let effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === REORDER_OBJECTS_MOVE_FIRST) {
      let {ids} = action
      let {list, map} = state

      let actualPath = map[ids]

      let dropPath = `${subPath(
        actualPath,
        actualPath.length - pathLength(actualPath)
      )}.9999`

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        let path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      let items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(list, map, null, path, resizeParent)
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(list, map, itm.id, null, resizeParent)
        list = updateOptions(list, map, itm.id)
      })

      let effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === REORDER_OBJECTS_MOVE_LAST) {
      let {ids} = action
      let {list, map} = state

      let actualPath = map[ids]

      let dropPath = `${subPath(
        actualPath,
        actualPath.length - pathLength(actualPath)
      )}.0`

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        let path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      let items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(list, map, null, path, resizeParent)
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(list, map, itm.id, null, resizeParent)
        list = updateOptions(list, map, itm.id)
      })

      let effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === REORDER_OBJECTS_MOVE_UP) {
      const {ids} = action
      let {list, map} = state

      let dropPath = map[ids]

      if (dropPath) {
        dropPath = incrementPath(dropPath)
        dropPath = incrementPath(dropPath)
      }

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        let path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      let items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(list, map, null, path, resizeParent)
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(list, map, itm.id, null, resizeParent)
        list = updateOptions(list, map, itm.id)
      })

      let effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === REORDER_OBJECTS_MOVE_DOWN) {
      let {ids} = action
      let {list, map} = state

      let dropPath = map[ids]
      let lastLayerCheck = dropPath[dropPath.length - 1] === '0'

      if (lastLayerCheck) {
        return state
      }

      dropPath = decrementPath(dropPath)

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        let path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      let items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(list, map, null, path, resizeParent)
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(list, map, itm.id, null, resizeParent)
        list = updateOptions(list, map, itm.id)
      })

      let effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === GROUP_OBJECTS || action.type === GROUP_OBJECTS_TO_LIST) {
      let {list, map} = state
      let {ids} = action

      if (ids.length === 0) {
        return state
      }

      let paths = ids.map(id => map[id])
      paths = paths.filter(path => pathLength(path) > 1)

      paths = removeChildren(sortPaths(paths))

      let group = emptyObject(
        {
          type: action.type === GROUP_OBJECTS_TO_LIST ? LIST : GROUP,
          children: [],
          width: 0,
          height: 0,
          depth: 0
        },
        state.list
      )

      let groupPath = getGroupPath(paths)

      if (!groupPath) {
        return
      }

      paths.forEach(path => {
        let obj = getObject(list, path)
        group.children.push(obj)
      })

      let pathsReversed = [...paths]
      pathsReversed.reverse()

      pathsReversed.forEach(path => {
        list = remove(list, path)
      })

      list = insert(list, groupPath, group)
      map = remapSiblings(list, map, groupPath)
      list = updateBounds(list, map, groupPath, resizeParent)

      let selection = [group.id]

      saveTouched(state.appId, list, map, null, paths)

      return updateIndices({
        ...state,
        list,
        map,
        selection,
        hoverSelection: [],
      })
    }

    if (action.type === UNITE_OBJECTS) {

      let {ids} = action

      if (ids.length === 0) {
        return state
      }

      const {paths, list, map, selection} = booleanOperation(state, ids,'unite')

      return updateIndices({
        ...state,
        list,
        map,
        selection,
        hoverSelection: [],
      })
    }

    if (action.type === INTERSECT_OBJECTS) {

      let {ids} = action

      if (ids.length === 0) {
        return state
      }

      const {paths, list, map, selection} = booleanOperation(state, ids,'intersect')



      return updateIndices({
        ...state,
        list,
        map,
        selection,
        hoverSelection: [],
      })
    }

    if (action.type === SUBTRACT_OBJECTS) {

      let {ids} = action

      if (ids.length === 0) {
        return state
      }

      const {paths, list, map, selection} = booleanOperation(state, ids,'subtract')



      return updateIndices({
        ...state,
        list,
        map,
        selection,
        hoverSelection: [],
      })
    }

    if (action.type === EXCLUDE_OBJECTS) {

      let {ids} = action

      if (ids.length === 0) {
        return state
      }

      const {paths, list, map, selection} = booleanOperation(state, ids,'exclude')



      return updateIndices({
        ...state,
        list,
        map,
        selection,
        hoverSelection: [],
      })
    }

    if (action.type === DIVIDE_OBJECTS) {

      let {ids} = action

      if (ids.length === 0) {
        return state
      }

      const {paths, list, map, selection} = booleanOperation(state, ids,'divide')



      return updateIndices({
        ...state,
        list,
        map,
        selection,
        hoverSelection: [],
      })
    }

    if (action.type === UNGROUP_OBJECTS) {
      let {ids} = action
      let {list, map} = state

      let paths = ids.map(id => map[id])
      let objects = paths.map(path => getObject(list, path))

      objects = objects.filter(obj => obj.type === GROUP)

      let selection = []

      objects.forEach(group => {
        let {id, children} = group
        let path = map[id]

        selection = selection.concat(children.map(c => c.id))


        if (group.angle) {
          children = children.map(obj => {
            let rotateX = group.x || obj.x
            let rotateY = group.y || obj.y

            const rotateWidth = group.width || obj.width
            const rotateHeight = group.height|| obj.height

            const rect = new PathRectangle(0, 0, obj.width, obj.height);

            const bl = rect.getBottomLeft(true),
                tl = rect.getTopLeft(true),
                tr = rect.getTopRight(true),
                br = rect.getBottomRight(true)
            ;

             const  segments = [
                new Segment(bl),
                new Segment(tl),
                new Segment(tr),
                new Segment(br)
              ];

            const box = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
              return new Segment(point, handleIn, handleOut)
            }))
            box.adjust(obj.x, obj.y)


            box.rotate(group.angle || 0, new PathPoint(rotateX + rotateWidth/2 , rotateY  + rotateHeight/2))
            return {
              ...obj,
              x: box.bounds.x,
              y: box.bounds.y
            }
          } )



        }

        

        list = remove(list, path)
        list = insert(list, path, ...children)
        map = remapSiblings(list, map, '0')
      })

      saveTouched(state.appId, list, map, null, paths)

      return updateIndices({
        ...state,
        list,
        map,
        selection,
      })
    }

    if (action.type === ZOOM) {
      let {relativeScale, relativeOffset, scale, offset} = action

      let newState = {...state.zoom}

      if (relativeScale) {
        scale = state.zoom.scale * relativeScale
      }

      if (relativeOffset) {
        let [diffX, diffY] = relativeOffset
        let [x, y] = state.zoom.offset

        offset = [x + diffX, y + diffY]
      }

      if (scale && !offset) {
        let xCenter = (window.innerWidth - 430) / 2 + 430
        let yCenter = (window.innerHeight - 64) / 2 + 64
        let center = [xCenter, yCenter]

        offset = getOffset(scale, center, state.zoom.scale, state.zoom.offset)
      }

      if (scale) {
        newState.scale = scale
      }

      if (offset) {
        newState.offset = offset
      }

      if (newState.scale > 64 || newState.scale < 1.0 / 16) {
        return state
      }

      return {
        ...state,
        zoomAppId: state.appId,
        zoom: newState,
      }
    }

    if (action.type === RESET_ZOOM) {
      let {list} = state
      let zoom = calculateZoom(getBoundingBox(list))

      return {...state, zoom}
    }

    if (action.type === '@@redux-undo/UNDO') {
      saveDiffed(state.appId, state.list)
    } else if (action.type === '@@redux-undo/REDO') {
      saveDiffed(state.appId, state.list)
    }

    if (action.type === SET_TOOL) {
      return {
        ...state,
        activeTab: null,
      }
    }

    if (action.type === SELECT_PARENT) {
      const {objectId} = action

      return {
        ...state,
        selectedParent: objectId,
      }
    }

    if (action.type === PAN) {
      return {
        ...state,
        panning: action.value,
      }
    }

    if (action.type === SET_LIBRARY_GLOBAL) {
      let {libraryGlobals, appId} = state
      let {libraryName, componentName, changes} = action

      libraryGlobals = {
        ...state.libraryGlobals,
        [libraryName]: {
          ...state.libraryGlobals[libraryName],
          [componentName]: deepMerge(
            state.libraryGlobals[libraryName]?.[componentName],
            changes
          ),
        },
      }

      //saveLibraryGlobals(appId, libraryGlobals)

      return {...state, libraryGlobals}
    }

    return state
  }
)


// ACTIONS

export const requestData = appId => ({
  type: REQUEST_DATA,
  appId,
})




export const setEditorDefault = (data) => dispatch => {
  dispatch({
    type: EDITOR_INIT_DEFAULT,
    data,
  })

  window.setTimeout(() => {
    dispatch({type: '@@redux-undo/CLEAR_HISTORY'})
  }, 0)

}



export const setDataV2 = (appId, data, app) => dispatch => {
  dispatch({
    type: SET_DATA_v2,
    appId,
    data,
    app,
  })

  window.setTimeout(() => {
    dispatch({type: '@@redux-undo/CLEAR_HISTORY'})
  }, 0)

}

export const setData = (appId, data, app) => dispatch => {

  dispatch({
    type: SET_DATA,
    appId,
    data,
    app,
  })

  window.setTimeout(() => {
    dispatch({type: '@@redux-undo/CLEAR_HISTORY'})
  }, 0)

}



export const resetObjects = (object, path = null, silent = false) => ({
  type: RESET_OBJECTS,
  object,
  path,
  silent,
  id: getId(),
})
export const createObject = (object, path = null, silent = false) => ({
  type: CREATE_OBJECT,
  object,
  path,
  silent,
  id: getId(),
})

export const updateObject = (id, object, undoGroupKey) => ({
  type: UPDATE_OBJECT,
  id,
  object,
  undoGroup: undoGroupKey && `${undoGroupKey}-${id}`,
})



export const updateObjects = objects => ({
  type: UPDATE_OBJECTS,
  objects,
})


export const changeObjectType = (id, newType) => ({
  type: CHANGE_OBJECT_TYPE,
  id,
  newType,
})

export const resizeObject = (id, object, skipSave = false) => ({
  type: RESIZE_OBJECT,
  id,
  object,
  skipSave,
})

export const positionObjects = (ids, offset, shiftKey, shouldSave = true) => ({
  type: POSITION_OBJECTS,
  ids,
  offset,
  shiftKey,
  shouldSave,
})

export const alignObjects = direction => ({
  type: ALIGN_OBJECTS,
  direction,
})
export const flipObjects = direction => ({
  type: FLIP_OBJECTS,
  direction,
})

export const deleteObject = id => ({
  type: DELETE_OBJECT,
  id,
})

export const reorderObjects = (ids, dropTarget, options) => ({
  type: REORDER_OBJECTS,
  ids,
  dropTarget,
  options,
})

export const reorderObjectsMoveFirst = ids => ({
  type: REORDER_OBJECTS_MOVE_FIRST,
  ids,
})

export const reorderObjectsMoveLast = ids => ({
  type: REORDER_OBJECTS_MOVE_LAST,
  ids,
})

export const reorderObjectsMoveUp = ids => ({
  type: REORDER_OBJECTS_MOVE_UP,
  ids,
})

export const reorderObjectsMoveDown = ids => ({
  type: REORDER_OBJECTS_MOVE_DOWN,
  ids,
})

export const groupObjectsToList = ids => ({
  type: GROUP_OBJECTS_TO_LIST,
  ids,
})
export const uniteObjects = ids => ({
  type: UNITE_OBJECTS,
  ids,
})
export const intersectObjects = ids => ({
  type: INTERSECT_OBJECTS,
  ids,
})
export const subtractObjects = ids => ({
  type: SUBTRACT_OBJECTS,
  ids,
})
export const excludeObjects = ids => ({
  type: EXCLUDE_OBJECTS,
  ids,
})
export const divideObjects = ids => ({
  type: DIVIDE_OBJECTS,
  ids,
})


export const groupObjects = ids => ({
  type: GROUP_OBJECTS,
  ids,
})

export const ungroupObjects = ids => ({
  type: UNGROUP_OBJECTS,
  ids,
})

export const setZoom = (scale, offset, relativeScale, relativeOffset) => ({
  type: ZOOM,
  scale,
  offset,
  relativeScale,
  relativeOffset,
})

export const resetZoom = () => ({type: RESET_ZOOM})

export const selectParent = id => ({
  type: SELECT_PARENT,
  objectId: id,
})

export const setPan = value => (dispatch, getState) => {
  const state = getState()
  const panning = getPanning(state)

  if (value === true && panning === true) return false

  return dispatch({
    type: PAN,
    value,
  })
}

export const setLibraryGlobals = (libraryName, componentName, changes) => ({
  type: SET_LIBRARY_GLOBAL,
  libraryName,
  componentName,
  changes,
})

export const booleanOperation = (state, ids, operation) => {

  let {list, map} = state


  let paths = ids.map(id => map[id])
  paths = paths.filter(path => pathLength(path) > 1)

  let nextPath = null

  for (let i = 0; i < paths.length; i += 1) {
    let obj =  getObject(list, paths[i])

    let groupCompoundPath = PathItem.create()
    if (obj.compound && obj.compound.length > 0) {
      groupCompoundPath = PathItem.create(obj.compound)
      groupCompoundPath.setClosed(obj.isClosed)
    } else {
      groupCompoundPath= PathItem.create(obj.points)
      groupCompoundPath.depth = obj.depth
      groupCompoundPath.setClosed(obj.isClosed)
    }

    if (nextPath) {
      nextPath = nextPath[operation](groupCompoundPath)
    } else {
      nextPath = groupCompoundPath
    }

    //
  }

  let path = paths.shift()

  paths = removeChildren(sortPaths(paths))
  let obj = getObject(list, path)

  const bounds = nextPath.bounds

  let compound = null
  let points = null

  if (nextPath instanceof CompoundPath) {
    compound = nextPath.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })
  } else {
    points = nextPath.points
  }



  obj = {
    ...obj,
    type: SHAPE,
    points,
    compound,
    width: bounds.width,
    height: bounds.height,
    x: bounds.x,
    y: bounds.y,
  }


  let pathsReversed = [...paths]
  pathsReversed.reverse()


  pathsReversed.forEach(pathToRemove => {
    let obj = getObject(list, pathToRemove)

    list = remove(list, pathToRemove)

    map = {...map}
    delete map[obj.id]
    map = remapSiblings(list, map, subPath(pathToRemove, pathToRemove.length - 1))

    let pieces = pathToRemove.split('.')
    let parentPath = pieces.slice(0, pieces.length - 1).join('.')
    let siblings = []

    if (parentPath === '') {
      siblings = list
    } else {
      let parentObj = getObject(list, parentPath)

      siblings = (parentObj && parentObj.children) || []
    }

    let pathPrefix = parentPath === '' ? '' : `${parentPath}.`

    siblings.forEach((sibling, position) => {
      map[sibling.id] = `${pathPrefix}${position}`
    })
  })


  list = update(list, path, obj)
  list = updateParentBounds(list,map, obj.id, null, resizeParent)
  list = updateOptions(list, map, obj.id)

  saveTouched(state.appId, list, map, null, paths)

  return {
    paths,
     list,
     map,
     selection: [obj.id]
   }

}
// SELECTORS

export const selectObjects = (state, ids = null) => {
  let list = state.editor.objects.present.list

  if (!ids) {
    return list
  }

  ids = uniqueElements(ids)

  return ids.map(id => getObject(list, getPath(state, id))).filter(o => o)
}

// Used by marquee selection
export const selectVisible = state => {
  return state.editor.objects.present.list.filter(o => !o.hidden)
}

export const selectObject = (state, id) => {
  let path = getPath(state, id)
  let objects = selectObjects(state)

  return getObject(objects, path)
}

export const getMap = state => {
  return state.editor.objects.present.map
}

export const getPath = (state, objectId) => {
  return state.editor.objects.present.map[objectId]
}

const getSelectedSub = (objects, selection) => {
  let {map, list} = objects

  let result = selection.map(id => getObject(list, map[id]))

  let idMap = {}

  return result.filter(obj => {
    if (!obj) {
      return false
    }

    let {id} = obj

    if (idMap[id]) {
      return false
    }

    idMap[id] = true

    return true
  })
}

export const getSelectedObjects = state => {
  let {objects} = state.editor
  let selection = objects.present.selection

  return getSelectedSub(objects.present, selection)
}

export const getSelectedIds = state => {
  return state.editor.objects.present.selection
}

export const getZoom = ({editor}) => editor.objects.present.zoom



export const getPage = state => {
  let {width, height, backgroundColor} = state.editor.objects.present

  return {width, height, backgroundColor}
}

export const getCurrentAppId = state => {
  let {appId} = state.editor.objects.present

  return appId
}

export const getObjectsOfType = (state, componentId, type) => {
  let {typeIndex} = state.editor.objects.present
  let index = (typeIndex[componentId] || {})[type]

  if (!componentId) {
    index = []

    for (let component of state.editor.objects.present.list) {
      index = index.concat((typeIndex[component.id] || {})[type] || [])
    }
  }

  return index || []
}

export const getLibraryInputs = (state, componentId, allowedDataTypes) => {
  let libraryObjects = getObjectsOfType(state, componentId, LIBRARY_COMPONENT)
  let app = state.apps.apps[getCurrentAppId(state)]

  // Results will have the form: { objectId, fieldId: [childComponent, prop] }
  let results = []

  let defaultDataType = dataTypes.TEXT

  for (let id of libraryObjects) {
    let obj = selectObject(state, id)

    if (!obj) continue

    let {libraryName, componentName} = obj
    let config = getAppComponent(app, libraryName, componentName)

    if (!config) continue

    // Loop through props
    for (let prop of config.props) {
      if (prop.role === 'formValue') {
        results.push({
          objectId: id,
          prop: [prop.name],
          dataType: prop.type || defaultDataType,
        })
      }
    }

    // Loop through child components
    for (let child of config.childComponents || []) {
      for (let prop of child.props) {
        if (prop.role === 'formValue') {
          results.push({
            objectId: id,
            prop: [child.name, prop.name],
            dataType: prop.type || defaultDataType,
          })
        }
      }
    }
  }

  if (allowedDataTypes) {
    results = results.filter(result => {
      return allowedDataTypes.includes(result.dataType)
    })
  }

  return results
}

export const getInputs = (state, componentId, objectId, objectType = INPUT) => {
  let inputs = []

  if (objectType === INPUT) {
    inputs = inputs.concat(
      getLibraryInputs(state, componentId, [dataTypes.TEXT])
    )
  }

  let inputIds = inputs.concat(getObjectsOfType(state, componentId, objectType))

  const list = state.editor.objects.present?.list

  inputIds = inputIds.map(id => {
    const path = getPath(state, id)

    let listParentIds = []

    for (let i = pathLength(path) - 1; i > 0; i -= 1) {
      const obj = getObject(list, subPath(path, pathLength(path) - i))

      if (!obj) continue
      if (obj.type === LIST) listParentIds.push(obj.id)
    }

    if (listParentIds.length === 0) return id

    return listParentIds.concat([id])
  })

  let objectListParents = []
  const path = getPath(state, objectId)

  for (let i = pathLength(path) - 1; i > 0; i -= 1) {
    const obj = getObject(list, subPath(path, pathLength(path) - i))

    if (!obj) continue
    if (obj.type === LIST) objectListParents.push(obj.id)
  }

  inputIds = inputIds.filter(id => {
    if (!Array.isArray(id)) return id
    const inputPath = id.slice(0, id.length - 1).join('.')
    const objectPath = objectListParents.join('.')

    return objectPath.startsWith(inputPath)
  })

  return inputIds
}

export const getImagePickers = (state, componentId) => {
  return getObjectsOfType(state, componentId, IMAGE_UPLOAD)
}

export const getFilePickers = (state, componentId) => {
  return getObjectsOfType(state, componentId, FILE_UPLOAD)
}

export const getDatePickers = (state, componentId) => {
  return getObjectsOfType(state, componentId, DATE_PICKER)
}

export const getSelects = (state, componentId, objectId) => {
  return getInputs(state, componentId, objectId, SELECT)
}

export const getLists = (state, componentId) => {
  return getObjectsOfType(state, componentId, LIST)
}

export const getCheckboxes = (state, componentId) => {
  return getLibraryInputs(state, componentId, [dataTypes.BOOLEAN])
}

export const getLoading = state => state.editor.objects.present.loading

export const getName = state => state.editor.objects.present.name

export const getComponent = (state, objectId) => {
  let path = getPath(state, objectId)

  if (!path) {
    return null
  }

  let componentPath = subPath(path, 1)
  let objects = selectObjects(state)
  let component = getObject(objects, componentPath)

  return component || null
}

export const getComponentId = (state, objectId) => {
  let component = getComponent(state, objectId)

  return (component && component.id) || null
}

export const getObjectPosition = (state, objectId) => {
  let object = selectObject(state, objectId)
  let component = getComponent(state, objectId)

  let {x, y, width, height} = object

  if (object.type === COMPONENT) {
    return object
  }

  return {
    width,
    height,
    x: x + component.x,
    y: y + component.y,
  }
}

export const getDataBinding = (state, objectId) => {
  let object = selectObject(state, objectId)

  return object && object.dataBindings && object.dataBindings[0]
}

export const getActions = (state, objectId) => {
  let object = selectObject(state, objectId)

  return (object && object.actions) || {}
}

export const getParentComponent = (state, objectId) => {
  const {list, map} = state.editor.objects.present

  if (objectId) {
    return getObject(list, getParentPath(map[objectId]))
  }

  return null
}
export const getRootComponent = (state) => {
  const {list, map} = state.editor.objects.present

  let [root] = list

  if (root) {
    return getObject(list, map[root.id])
  }

  return null
}
export const getPlatform = state => {
  let appId = getCurrentAppId(state)
  let app = state?.apps?.apps?.[appId]

  return app?.primaryPlatform || 'mobile'
}

export const getYOffset = state => {
  let platform = getPlatform(state)

  if (platform === 'web') {
    return 20
  }

  return 0
}

export const getSelectedParent = state => {
  const {selectedParent} = state.editor.objects.present

  if (!selectedParent) {
    return null
  }

  const objects = selectObjects(state, [selectedParent])

  return objects ? objects[0] : null
}

export const getPanning = state => {
  return state.editor.objects.present.panning
}

export const getLibraryGlobals = (state, libraryName, componentName) => {
  let globals = state.editor.objects.present.libraryGlobals

  return globals[libraryName]?.[componentName] || {}
}
