// computed_objects.js

import immutabilityHelper from 'immutability-helper'
import deepEqual from 'deep-equal'
import chroma from 'chroma-js'
import qs from 'qs'
import shortUuid from 'short-uuid'
import {v4} from 'uuid'

const BASE_36_ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz'

import {
  backgroundStyle,
  COMPONENT,
  dataTypes,
  ELLIPSE,
  FILE_UPLOAD,
  GROUP,
  IMAGE_UPLOAD,
  LABEL,
  LIBRARY_COMPONENT,
  LIST,
  RECTANGLE,
  SHAPE
} from 'common/constants'

import { PathItem, Point as PathPoint, Rectangle as PathRectangle, Segment, Size } from "utils/vector"
import { CompoundPath, Point } from "utils/vector/classes"

import { saveComponents } from 'utils/io'
import { unsafeGetStore } from 'utils/auth'

import { loadComponent } from 'ducks/apps/actions'


const getObject = (objects, path) => {
    
  if (!objects || !path || path.length === 0) {
    return null
  }

  if (typeof path === 'string') {
    path = path.split('.')
  }

  if (path.length === 1) {
    return objects[path[0]];
  }

  const newObject = objects[path[0]]

  return newObject && getObject(newObject.children, path.slice(1)) || null
}

const evaluate = (newObject, object) => {
  return Object.keys(newObject).reduce((diff, key) => {
    diff[key] = typeof newObject[key] === 'function' ? newObject[key](object) : newObject[key]
    return diff
  }, {})
}

const updateObjectWidth = object => {
  return object
}

const _extends = Object.assign || function (target) {

  for (let i = 1; i < arguments.length; i++) {

    const source = arguments[i]

    for (let key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key]
      }
    }

  }

  return target
}

const internalMove = (obj) => {

  const {compound, points} = obj

  let shape = PathItem.create()
  if (compound && compound.length > 0) {
    shape = PathItem.create(compound)
  } else if (points && points.length > 0) {
    shape = PathItem.create(points)
  }

  if (
      (!shape.segments && !shape.children) ||
      ((shape.segments && !shape.segments.length) && (shape.children && !shape.children.length))
   ) {

    return {
      compound,
      points
    }
  }

  let bounds = shape.bounds


  //shape.adjust(obj.x, obj.y )
  shape.adjust(obj.x , obj.y )

  // let unit = window.devicePixelRatio === 2 ? 2 : 1
  //shape.rotate(angle || 0, shape.getRotatePoint(obj.x/unit, obj.y/unit));

  let newPoints = []
  let newCompound = []

  if (shape instanceof CompoundPath) {
    newCompound = shape.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })
  } else {
    newPoints = shape.points
  }

  return {
    points: newPoints,
    compound: newCompound,
  }

}

const translateChildren = (group, oldGroup) => {

  if (!group.children || !group.children.length) {
    return group
  }

  const diffX = group.x - oldGroup.x
  const diffY = group.y - oldGroup.y
  const diffA = group.angle - oldGroup.angle

  if (!diffX && !diffY && !diffA) {
    return group
  }

  const translated = { ...group }

  translated.children = translated.children.map(child => {

    const movedChild = internalMove({
      ...child,
      x: child.x + diffX,
      y: child.y + diffY,
    })

    return translateChildren(
      {
        ...child,
        ...movedChild,
        x: child.x + diffX,
        y: child.y + diffY,
        angle: child.angle + diffA,
      },
      child
    )

  })

  return translated
}


const getAppComponent = (app, libraryName, componentName) => {
  let library = getAppLibrary(app, libraryName)

  if (!library) {
    return null
  }

  return library.config.components.filter(c => c.name === componentName)[0]
}

const getLibrary = (libraryName, version) => {
  let versionMap = window.protonLibraries?.[libraryName]
  if (!version && versionMap) version = Object.keys(versionMap)?.[0]

  return versionMap?.[version]
}

const getLibraryDependencies = app => {

  // TODO: Add app-specific libraries
  let localLibraries = getLocalLibraries()

  const libraries = app?.libraries || []

  let libs = libraries?.filter(lib => {
    return !localLibraries.includes(lib.name)
  })

  libs = libs.concat(
    localLibraries.map(lib => ({
      name: lib,
      version: 'dev',
    }))
  )

  return libs
}

const getAppLibrary = (app, libraryName) => {
  let dependencies = getLibraryDependencies(app)
  let dependency = dependencies?.filter(({ name }) => name === libraryName)?.[0]
  let version = dependency?.version

  return getLibrary(libraryName, version)
}

const deepSet = (obj, key = [], value) => {
  if (key.length === 0) {
    return value
  }

  if (!obj) {
    obj = {}
  }

  return {
    ...obj,
    [key[0]]: deepSet(obj[key[0]], key.slice(1), value),
  }
}

const assetURL = filename => {

  let params = { auto: 'compress' }

  return `${process.env.REACT_APP_IMAGE_BASE_URL}/${filename}?${qs.stringify(
    params
  )}`

}

const noop = () => {}

function contrastWithBackground(bgColor) {

  if (!bgColor) {
    return
  }

  if (bgColor === 'transparent') {
    bgColor = 'rgba(0, 0, 0, 0)'
  }

  let alpha = chroma(bgColor).alpha()
  bgColor = chroma(bgColor).alpha(0.85)
  let adjustedColor = chroma.mix('#fff', bgColor, Math.sqrt(alpha))

  return chroma.contrast(adjustedColor, '#fff') >= 2.5 ? '#fff' : '#000'
}

const getRGBA = color => {

  if (typeof color === 'string') {
    color = chroma(color).rgba()
  }

  if (Array.isArray(color)) {
    return `rgba(${color.join(', ')})`
  }

  let c = color

  return `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a})`
}

function brighten(color) {
  return getRGBA(chroma(color).brighten().rgba())
}

function darken(color) {
  return getRGBA(chroma(color).darken().rgba())
}

function normalizeColor(value, branding = defaultBranding, ctx = {}) {

  if (!value || value[0] !== '@') {
    return value
  }

  if (value.startsWith('@contrast:')) {
    const sibling = value.substr(10)

    return contrastWithBackground(normalizeColor(ctx[sibling], branding))
  }

  const match = /^@(primary|secondary|background|text)(Light|Dark)?$/.exec(
    value
  )

  if (!match) {
    return value
  }

  const color = branding[match[1]]

  switch (match[2]) {
    case 'Light':
      return brighten(color)
    case 'Dark':
      return darken(color)
    default:
      return color
  }

}

const getPropValue = (prop, value, getLabel, opts = {}) => {

  let { branding, children, props, libraryGlobals } = opts

  if (prop.global) {
    value = libraryGlobals[prop.name]
  }

  if (prop.type === dataTypes.LIST) {
    return [1, 2, 3].map(id => ({
      ...children,
      id,
    }))
  }

  if (value && Array.isArray(value) && prop.type === 'text') {
    return value
      .map(itm => getPropValue(prop, itm, getLabel, children, opts))
      .join('')
      .replace(/ +/g, ' ')
  }

  if (value && value.type === 'formula') {
    if (value.formula.length === 1 && typeof value.formula[0] === 'string') {
      return +value.formula[0]
    }

    return 0
  }

  if (value && value.type === 'binding') {
    if (prop.type === dataTypes.TEXT) return getLabel(value.source)

    if (prop.type === dataTypes.IMAGE) {
      if ('options' in value && typeof value.options !== 'undefined') {
        const { options } = value

        if (typeof options.placeholderImageEnabled === 'undefined') {
          return undefined
        }

        if (typeof options.placeholderImage === 'undefined') return undefined

        const { placeholderImage, placeholderImageEnabled } = options

        if (placeholderImageEnabled && placeholderImage) {
          return assetURL(placeholderImage)
        }
      }
    }

    return undefined
  }

  if (value && prop.type === 'action') {
    return noop
  }

  if (value && prop.type === dataTypes.COLOR) {
    return normalizeColor(value, branding, props)
  }

  return value
}

const normalizeFont = (value, branding = {}) => {

  const { fonts = {} } = branding

  if (!value || typeof value !== 'string' || !fonts) return null

  // the value "system" is the legacy fontFamily value
  if (value === 'system') {
    return fonts?.body?.family || null
  }

  const match = /^@(heading|body)?$/.exec(value)

  if (!match || !fonts[match[1]]) return null

  return fonts[match[1]].family
}

const defaultFonts = '-apple-system, "SF Pro Text", sans-serif'

const getFontFamily = (value, branding = {}) => {

  const font = normalizeFont(value, branding)

  if (!font || font === 'default') return defaultFonts

  return `${font}, ${defaultFonts}`
}

const getPropStyles = (prop, config, opts = {}) => {

  const { branding } = opts

  let propStyles = { ...config?.styles }
  const keys = Object.keys(propStyles)

  const target = prop?.styles?.[config.name] || prop

  for (const key of keys) {
    let style = target?.[key] || config?.styles?.[key]

    switch (key) {
      case 'fontFamily': {
        propStyles[key] = getFontFamily(style, branding)

        break
      }
      case 'fontSize': {
        if (typeof style === 'number') {
          style = `${style}px`
        } else if (typeof style === 'string') {
          if (!style.includes('px')) style = `${style}px`
        }

        propStyles[key] = style

        break
      }
      case 'color': {
        propStyles[key] = normalizeColor(style, branding, prop)

        break
      }
      case 'textAlignment': {
        propStyles.textAlign = style
        delete propStyles.textAlignment

        break
      }

      default: {
        propStyles[key] = style

        break
      }
    }
  }

  return propStyles
}

const evaluateLibraryProps = (config, props = {}, getLabel, { branding, libraryGlobals } = {}) => {

  let result = {}
  let referenceMap = {}
  let childComponents = config?.childComponents || []

  for (let child of childComponents) {
    let value = evaluateLibraryProps(child, props[child.name], getLabel, {
      branding,
      libraryGlobals: libraryGlobals?.[child.name] || {},
    })

    if (child.role === 'listItem' && child.reference) {
      let ref = child.reference

      referenceMap[ref] = referenceMap[ref] || {}
      referenceMap[ref][child.name] = value

      if (value.styles) {
        result = deepSet(result, [child.name], value)
      }
    } else {
      result[child.name] = value
    }
  }

  let refProps = config?.props?.filter(p => p.role === 'listItem')
  let normalProps = config?.props?.filter(p => p.role !== 'listItem')

  if (refProps) {
    for (let prop of refProps) {
      let value = getPropValue(prop, props[prop.name], getLabel, {
        branding,
        props,
        libraryGlobals,
      })

      let ref = prop.reference

      referenceMap[ref] = referenceMap[ref] || {}
      referenceMap[ref][prop.name] = value
    }
  }

  if (normalProps) {
    for (let prop of normalProps) {
      let propValue = getPropValue(prop, props[prop.name], getLabel, {
        children: referenceMap[prop.name],
        branding,
        props,
        libraryGlobals,
      })

      if (prop.role === 'autosaveInput') {
        result[prop.name] = { value: propValue }
      } else {
        result[prop.name] = propValue
      }

      if (prop.styles) {
        const key = ['styles', prop.name]

        result = deepSet(result, key, getPropStyles(props, prop, { branding }))
      }
    }
  }

  return result
}

const updateComponentBounds = (obj, libraryGlobals) => {

  let { libraryName, componentName } = obj
  let library = getAppLibrary(null, libraryName)
  let config = getAppComponent(null, libraryName, componentName) || {}
  let resizeY = !!config?.resizeY
  let resizeX = 'resizeX' in config ? config?.resizeX : true

  // Skip if vertically-resizable
  if (resizeY && resizeX) {
    return obj
  }

  let Component = library?.components?.[componentName]

  let props = evaluateLibraryProps(
    config,
    obj.attributes,
    () => 'Hello world',
    { libraryGlobals }
  )

  let el = document.createElement('div')

  if (resizeX) {
    el.style.width = `${obj.width}px`
  }

  el.style.position = 'fixed'
  el.style.top = '100%'
  el.style.top = 0
  el.style.zIndex = 100000
  el.style.opacity = 0
  el.style.pointerEvents = 'none'
  el.style.backgroundColor = 'red'

  document.body.appendChild(el)

  let rect

  try {
    ReactDOM.render(<Component editor {...props} />, el);
    rect = el.getBoundingClientRect();
  } catch (err) {
    console.error('Ошибка в компоненте библиотеки:', err);
    throw err;
  }

  document.body.removeChild(el)

  if (!rect || rect.height === 0 || rect.width === 0) {
    return obj
  }

  let { width, height } = rect
  let result = { ...obj }

  if (!resizeX) {
    result.width = width
  }

  if (!resizeY) {
    result.height = height
  }

  return result
}

const createRectangle =(obj, zoom)=>{

  const kappa = 4 * (Math.sqrt(2) - 1) / 3

  const {width, height, points, compound, isClosed, x, y, borderRadius,angle} = obj

  let path = PathItem.create()

  const rect = new PathRectangle(x, y, width, height);
  let r =  new PathPoint(borderRadius ? [borderRadius, borderRadius] : [0,0])

  const bl = rect.getBottomLeft(true),
    tl = rect.getTopLeft(true),
    tr = rect.getTopRight(true),
    br = rect.getBottomRight(true)
  ;

  let segments = [];
  if (!r || r.isZero()) {
    segments = [
      new Segment(bl),
      new Segment(tl),
      new Segment(tr),
      new Segment(br)
    ];
  } else {

    r = Size.min(new Size(borderRadius, borderRadius), rect.getSize(true).divide(2));
    const rx = r.width,
      ry = r.height,
      hx = rx * kappa,
      hy = ry * kappa;
    segments = [
      new Segment(bl.add(rx, 0), null, [-hx, 0]),
      new Segment(bl.subtract(0, ry), [0, hy]),
      new Segment(tl.add(0, ry), null, [0, -hy]),
      new Segment(tl.add(rx, 0), [-hx, 0], null),
      new Segment(tr.subtract(rx, 0), null, [hx, 0]),
      new Segment(tr.add(0, ry), [0, -hy], null),
      new Segment(br.subtract(0, ry), null, [0, hy]),
      new Segment(br.subtract(rx, 0), [hx, 0])
    ];
  }

  let newCompound = []
  let newPoints = []


  path = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
    return new Segment(point, handleIn, handleOut)
  }))

  path.setClosed(isClosed)
  //path.scale(zoom.scale)
  path.adjust(x, y)

  let unit = window.devicePixelRatio === 2 ? 2 : 1
 // path.rotate(angle || 0, path.getRotatePoint(x/unit, y/unit));

  if (path instanceof CompoundPath) {
    newCompound = path.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })
  } else {
    newPoints = path.points
  }

  obj = {
    ...obj,
    compound :newCompound ,
    points : newPoints,
    isClosed: true
  }
  return obj
}

const resizeB = {
  LEFT: 'right',
  RIGHT: 'left',
  TOP: 'bottom',
  BOTTOM: 'top'
}

const updateShapeBounds = (obj, changes) => {

  const { activeResize } = changes
  const { compound, points } = obj

  let shape = PathItem.create()

  if (compound && compound.length > 0) {
    shape = PathItem.create(compound)
  } else if (points && points.length > 0) {
    shape = PathItem.create(points)
  }

  let bounds = shape.bounds

  const scaleX = changes.width / bounds.width;
  const scaleY = changes.height / bounds.height;
  const prevPos = new Point(bounds.top, bounds.left);

  let centerPoint = []

  if (activeResize)
  Object.keys(activeResize).map((k) => {
    centerPoint.push(bounds[resizeB[k]])
  })

  shape.scale(new Point(scaleX, scaleY), new Point(...centerPoint))

  let newPoints = []; let newCompound = []

  if (shape instanceof CompoundPath) {

    newCompound = shape.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })

  } else {
    newPoints = shape.points
  }

  shape.setPosition(prevPos)

  bounds = shape.bounds

  if (obj.type === RECTANGLE) {

    obj = {
      ...obj,
      width: bounds.width,
      height: bounds.height
    }
    const rect = createRectangle(obj)
    newPoints = rect.points
  }

  return {
    points: newPoints,
    compound: newCompound,
    /*x: bounds.left,
    y: bounds.top,*/
    width: bounds.width,
    height: bounds.height
  }
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
      Object.defineProperty(obj, key, {
          value: value,
          enumerable: true,
          configurable: true,
          writable: true
      });
  } else {
      obj[key] = value;
  }

  return obj;
}

const buildUpdate = function (path, obj, insert = false) {

  const removeCount = insert ? 0 : 1

  if (!path) {
    path = []
  }

  if (typeof path === 'string') {
    path = path.split('.')
  }

  if (path.length === 0) {
    return {}
  }

  if (path.length === 1) {

    const spliceObj = [path[0], removeCount]

    if (obj) {
      spliceObj.push(obj);
    }

    return {
      $splice: [spliceObj]
    }

  }

  return _defineProperty({}, path[0], {
    children: buildUpdate(path.slice(1), obj, insert)
  })

}

const update = (list, path, newObject) => {
  return immutabilityHelper(list, buildUpdate(path, newObject));
}

const remove = (objects, path) => {
  return update(objects, path);
}

const subPath = (path, length) => {
  return (path || '').split('.').slice(0, length).join('.');
}

const pathLength = (path) => {
  if (!path) {
      return 0;
  }

  return path.split('.').length;
}

const removeInfinity = (val, fallback) => {

  if (!isFinite(val)) {
      return fallback;
  }

  return val;
}

const _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj
}

const shallowEqual = (obj1, obj2, fields) => {
    
  if (!obj1 || !obj2 || (typeof obj1 === 'undefined' ? 'undefined' : _typeof(obj1)) !== 'object' || (typeof obj2 === 'undefined' ? 'undefined' : _typeof(obj2)) !== 'object') {
      return obj1 === obj2;
  }

  if (!fields) {
      fields = Object.keys(_extends({}, obj1, obj2));
  }

  for (let i = 0; i < fields.length; i += 1) {
      if (obj1[fields[i]] !== obj2[fields[i]]) {
          return false;
      }
  }

  return true;
}

const updateBounds = function (list, map, path) {

  const shouldUpdate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  const obj = getObject(list, path);

  if (!obj) {
      return list;
  }

  if (shouldUpdate && !shouldUpdate(obj)) {
      return list;
  }

  let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity,
      maxD = obj.depth || 0;


  for (const child of obj.children){

    if (child.x < minX) {
      minX = child.x
    }

    if (child.x + child.width > maxX) {
      maxX = child.x + child.width
    }

    if (child.y < minY) {
      minY = child.y
    }

    if (child.y + child.height > maxY) {
      maxY = child.y + child.height
    }

    if (child.depth >= maxD) {
      maxD = child.depth
    }

  }

  obj.compound?.forEach(function (child) {
    if (child.depth >= maxD) {
      maxD = child.depth
    }
  })

  const x = removeInfinity(minX, 0);
  const y = removeInfinity(minY, 0);
  const width = removeInfinity(maxX - minX, 0);
  const height = removeInfinity(maxY - minY, 0);

  const updatedObj = _extends({}, obj, {
      x: x,
      y: y,
      width: width,
      height: height,
      depth: maxD

  });

  if (!shallowEqual(obj, updatedObj, ['x', 'y', 'width', 'height', 'depth'])) {
      list = update(list, path, updatedObj);
      const parentPath = subPath(path, pathLength(path) - 1);
      list = updateBounds(list, map, parentPath, shouldUpdate);
  }

  return list;
}

const updateParentBounds = (list, map, id, path, shouldUpdate) => {

  path = path || map[id];

  if (!path) {
      return list;
  }

  const parentPath = subPath(path, pathLength(path) - 1);
  
  return updateBounds(list, map, parentPath, shouldUpdate);
}

const resizeParent = obj => {
  return (
    obj.type !== COMPONENT &&
    (obj.type !== LIST ||
      obj.width === 0 ||
      obj.height === 0 ||
      obj.width === Infinity)
  )
}

const updateOptions = (list, map, groupId, path = null) => {

  if (!path) {
    path = map[groupId]
  }

  let obj = getObject(list, path)

  if (obj.type !== GROUP) {
    return updateParentOptions(list, map, obj.id)
  }

  let newObj = obj


  if (obj !== newObj) {
    list = update(list, path, newObj)
  }

  return list
}

const updateParentOptions = (list, map, objectId) => {
  
  let path = map[objectId]

  if (pathLength(path) > 1) {
    let parentPath = subPath(path, pathLength(path) - 1)
    let parentObj = getObject(list, parentPath)
    let parentId = parentObj.id

    list = updateOptions(list, map, parentId)
  }

  return list
}

const getParentPath = (path) => {
  if (!path) {
      return null;
  }
  const pieces = path.split('.');
  const parentPath = pieces.slice(0, pieces.length - 1).join('.');

  if (parentPath === '') {
      return null;
  }

  return parentPath;
}

const getSiblings = (list, path) => {
  const parentPath = getParentPath(path);

  if (parentPath === null) {
      return list;
  }

  const parentObj = getObject(list, parentPath);
  return parentObj && parentObj.children || [];
}





// ------------




const joinPaths = (...paths) => {
  return paths.filter(Boolean).join('.')
}

const remapSiblings = (list, map = {}, path = '0') => {

  map = { ...map }

  const parentPath = getParentPath(path)
  const siblings = getSiblings(list, path)

  siblings.forEach((obj, position) => {

    const objPath = joinPaths(parentPath, String(position))
    map[obj.id] = objPath

    if (obj.children) {
      const childrenPath = joinPaths(objPath, '0')
      const childrenMap = remapSiblings(list, {}, childrenPath)
      map = { ...map, ...childrenMap }
    }

  })

  return map
}

const identity = (itm) => {
  return itm
}

const uniqueElements = (arr, getter = identity, deep = false) => {

  const set = new Set(); const result = []

  arr.forEach(element => {

    const val = getter(element)

    if (deep) {

      const exists = result.some(item => deepEqual(getter(item), val))
      if (!exists) {
        result.push(element);
      }

    } else {

      if (!set.has(val)) {
        result.push(element); set.add(val)
      }

    }

  })

  return result
}


const deleteLayer = async (appId, layerId) => {

  let endpointURL = `${apiURL}/layers/${layerId}`

  return axios.delete(endpointURL, {headers: {'X-Requested-With': 'XMLHttpRequest'}})

}

const deleteComponent = async (appId, componentId) => {
  return await deleteLayer(appId, componentId)
}

const traverse = (objects, func, traverseChildren = () => true, parentObj) => {

  for (const [index, obj] of objects.entries()) {

    func(obj, parentObj, objects[index - 1], objects[index + 1])

    if (obj && obj.children && traverseChildren(obj)) {

      if (!Array.isArray(obj.children)) {
        throw new Error('obj.children is not an Array: ' + JSON.stringify(obj))
      }

      traverse(obj.children, func, traverseChildren, obj)

    }
  }

}


const getActions = (...arrays) => {
  let result = {}

  for (let array of arrays) {
    traverse(array, obj => {
      if (obj.actions) {
        result[obj.id] = obj.actions
      }
    })
  }

  return result
}

// функция которая сохраняет изменения в draft
const save = (appId, components, calc) => {

  if (!appId || !components || Object.keys(components).length === 0) {
    
    console.error('Attempted to save with invalid params:', {
      appId,
      components,
    })

    return
  }

  let outputComponents = {}

  for (let componentId of Object.keys(components)) {
    let component = {
      ...components[componentId],
      objects: components[componentId].children,
    }

    delete component.children

    outputComponents[componentId] = component

    component = {
      ...component,
      actions: getActions(component.objects, [
        { id: component.id, actions: component.componentActions },
      ]),
    }

    window.setTimeout(() => {
      unsafeGetStore().dispatch(loadComponent(appId, componentId, component))
    }, 0)

  }

  saveComponents(appId, outputComponents, calc)

}

const calculate = (components) => {

  let result = {}

  const unit = 1 / 0.2645833333
  const coff = 0.264583333

  const getOverflows = (mod) => {

      const arr = Object.values(components).sort(function (a, b) {
          return a.order - b.order;
      })

      function flattenLayer(objects, flatten) {

          flatten = flatten || {}

          if (!Array.isArray(objects)) {
              objects = [objects]
          }

          objects.forEach((item) => {

              if (item.type === GROUP) {
                  item.children?.forEach((obj) => {
                      obj = Object.assign({}, obj)
                      flattenLayer(obj, flatten)
                  })
                  return
              }

              if (item.compound && item.compound.length > 0) {
                  item.compound?.forEach((obj) => {
                      flattenLayer(obj, flatten)
                  })
                  return
              }

              if (item.points && item.points.length > 0) {
                  let p = PathItem.create(item.points)

                  p.setClosed(true)
                  p.flatten()
                  p.reorient(true/*res.getFillRule() === 'nonzero'*/, true);
                  p.adjust(0, 0)

                  const bounds = p.bounds


                  item = Object.assign({}, {
                      id: getId(),
                      name: item.name,
                      area: Math.round(p.getArea()) ,
                      length: Math.round(p.getLength() ),
                      depth: item.depth,

                  })
              }


              flatten[item.id] = item
          })


          return Object.values(flatten)
      }

      let overflow = {}

      let flatten = []
      let cloned = []


      const crat = 5 * unit

      arr.forEach((component) => {

          let depths = []

          if (mod < component.depth) {
              const len = ~~(component.depth / mod);
              depths = new Array(len).fill(mod);

              if (component.depth % mod !== 0) {
                  let module = component.depth % mod

                  const len2 = ~~(module / crat);
                  depths.push(len2 * crat)
              }
          } else {
              const len2 = ~~(component.depth / crat);
              depths.push(len2 * crat)
          }

          let ff = flattenLayer(component.children)
          flatten = [...flatten, ...ff]


          depths.forEach((depth, index) => {
              let elements = []

              cloned = flatten.slice()

              cloned.forEach((flat, index) => {
                  const obj = Object.assign({}, flat)

                  if (obj.depth - depth > 0) {
                      obj.depth = depth
                      flat.depth -= depth
                      elements.push(obj)
                  } else {
                      flatten.splice(index, 1)
                      elements.push(obj)
                  }
              })

              if (!overflow[component.id]) {
                  overflow[component.id] = []
              }

              overflow[component.id].push({depth: depth, elements: elements})

          })


      })

      return overflow
  }

  const elements = getOverflows(50 * unit)



  const calc = (r, shape, parent) => {

      const {width, height, depth, x, y, points, compound, id, angle} = shape

      let path = PathItem.create()

      if (compound && compound.length > 0) {
          path = PathItem.create(compound)
      } else if (points && points.length > 0) {
          path = PathItem.create(points)
      }
      path.setClosed(true)
      path.flatten()
      path.reorient(true/*res.getFillRule() === 'nonzero'*/, true);
      path.adjust(0, 0)

      const coff = 0.264583333

      r[shape.id] = {
        area: Math.round(path.getArea() * Math.pow(coff, 2)),
        length: Math.round(path.getLength() * coff)
      }
  }


/*    Object.keys(elements).forEach((layerIdx) => {
      result[layerIdx] = {}
      elements[layerIdx].forEach((subLayer, subLayerId) => {
              result[layerIdx][subLayerId] = {}
              subLayer.elements.forEach((el) => {
                  const lr = {}
                  calc(lr, el)

                  result[layerIdx][subLayerId] = {
                      ...result[layerIdx][subLayerId],
                      ...lr
                  }



              })
          })
  })*/

  return elements

}

let prevList = null

const saveTouched = (
  appId,
  list,
  map,
  objectIds,
  objectPaths,
  deletes,
  libraryGlobals
) => {

  let paths = objectPaths

  prevList = list

  if (!paths) {
    paths = objectIds.map(id => map[id])
  }

  let componentPaths = uniqueElements(
    paths.map(path => {
      return subPath(path, 1)
    })
  )

  if (deletes) {
    deletes.forEach(id => deleteComponent(appId, id))
  }

  let components = {}

  componentPaths.forEach(path => {

    let component = getObject(list, path)

    if (component) {
      components[component.id] = component
    }

  })

  let allComponentPaths = uniqueElements(
    Object.values(map).map(path => {
      return subPath(path, 1)
    })
  )

  let allComponents = {}

  allComponentPaths.forEach(path => {
    let component = getObject(list, path)

    if (component) {
      allComponents[component.id] = component
    }
  })

  if (Object.keys(components).length > 0) {
    save(appId, components, calculate(allComponents))
  }

}

const comparePaths = (a, b) => {

  const aPieces = a.split('.');
  const bPieces = b.split('.');
  let maxCount = Math.max(aPieces.length, bPieces.length);

  for (let i = 0; i < maxCount; i += 1) {
    if (+aPieces[i] > +bPieces[i] || bPieces[i] === undefined) {
      return 1;
    }

    if (+bPieces[i] > +aPieces[i] || aPieces[i] === undefined) {
      return -1;
    }
  }

  return 0;
}

const sortPaths = (paths) => {
  paths = [...paths]
  paths.sort(comparePaths)
  return paths
}

const getComponentPaths = (paths) => {

  const componentPaths = paths = paths.map(function (p) {
    return subPath(p, 1)
  })

  return sortPaths(Array.from(new Set(componentPaths)))
}

const indexByType = objects => {

  let result = {}

  traverse(objects, obj => {
    let { id, type } = obj

    if (!result[type]) {
      result[type] = []
    }

    result[type].push(id)
  })

  return result
}

const updateIndices = (state, paths = null) => {

  let componentPaths = Object.keys(state.list)

  if (paths) {
    componentPaths = getComponentPaths(paths.map(id => state.map[id]))
  }

  let typeIndex = {...state.typeIndex}

  componentPaths.forEach(path => {
    let component = state.list[path]

    typeIndex[component.id] = indexByType(component.children)
  })

  return {
    ...state,
    typeIndex,
  }
}

const leftPad = (str, length, character) => {
  return str.padStart(length, character)
}

const getId = () => {
  const translater = shortUuid(BASE_36_ALPHABET)
  const id = translater.fromUUID(v4())
  return leftPad(id, 26, '0')
}

const getTypeName = type => {
  switch (type) {
    case IMAGE_UPLOAD:
      return 'image-picker'
    case FILE_UPLOAD:
      return 'file-picker'
    case SECTION:
      return 'rectangle'
    case COMPONENT:
      return 'screen'
    case LABEL:
      return null
    default:
      return type || 'untitled'
  }
}

const isDefaultName = (typeName, name) => {
  return !name || name?.includes(typeName)
}

const capitalize = str => {

  if (!str) return str

  let first = str.substring(0, 1).toUpperCase()
  let rest = str.substring(1)

  return `${first}${rest}`
}

const createName = (type, name, list) => {

  let largestSuffix = 0

  if (!type) {
    return 'Untitled'
  }

  let typeName = getTypeName(type, name)

  if (!isDefaultName(typeName, name)) {
    typeName = name
  }

  let baseName = null

  if (typeName) {
    let nameParts = typeName.split(/[^a-zA-Z]+/g)
    baseName = nameParts.map(p => capitalize(p)).join(' ')
  }

  if (getTypeName(type, name) === 'screen' && name) {
    baseName = capitalize(name)
  }

  traverse(list, obj => {
    const lowerBaseName = baseName?.toLowerCase()

    if (obj.name?.toLowerCase().startsWith(lowerBaseName)) {
      let match = obj.name.match(/\s(\d+)$/)

      if (match) {
        largestSuffix = Math.max(largestSuffix, match[1])
      } else {
        largestSuffix = Math.max(largestSuffix, 1)
      }
    }
  })

  if (largestSuffix > 0) {
    return `${baseName} ${largestSuffix + 1}`
  }

  return baseName
}

const getRandom = (color) => {

  if (color === '@background') {
    return 'rgba(255,255,255, 1)'
  }

  if (color) {
    return color
  }

  return 'rgba(207,207,207, 0.85)'

}

const emptyObject = (obj, list, setId = true) => {
  
  let id = setId ? getId() : obj.id

  let newObj = {
    ...defaults[obj.type],
    ...obj,
    name: createName(obj.type, obj.name, list),
    id,
    backgroundStyle: backgroundStyle.COLOR,
    backgroundColor: getRandom(obj.backgroundColor),
  }

  for (const symbol of Object.getOwnPropertySymbols(newObj)) {
    delete newObj[symbol]
  }

  if (newObj.type !== GROUP && newObj.type !== COMPONENT) {
    newObj.x = newObj.x || 0
    newObj.y = newObj.y || 0
  }

  return newObj
}

const isChildPath = (parentPath, childPath) => {
  if (pathLength(childPath) <= pathLength(parentPath)) {
      return false;
  }

  return subPath(childPath, pathLength(parentPath)) === parentPath;
}

const anyField = (obj, fields, func) => {
  for (let i = 0; i < fields.length; i += 1) {
      const field = fields[i];

      if (func(obj[field])) {
          return true;
      }
  }

  return false;
}

const getObjectsInRect = (objects, rect) => {

  if (!rect) {
      return [];
  }

  const x = rect.x,
      y = rect.y,
      width = rect.width,
      height = rect.height;
  const results = [];
  objects.forEach(function (obj) {
      if (anyField(obj, ['x', 'y', 'width', 'height'], function (f) {
          return typeof f !== 'number';
      })) {
          return;
      }

      if (!(obj.x + obj.width < x || x + width < obj.x) && !(obj.y + obj.height < y || y + height < obj.y)) {
          results.push(obj.id);
      }
  });
  return results;
}

const getIntersection = (obj1, obj2) => {
  if (getObjectsInRect([obj1], obj2).length === 0) {
    return null
  }

  let x1 = Math.max(obj1.x, obj2.x)
  let y1 = Math.max(obj1.y, obj2.y)
  let x2 = Math.min(obj1.x + obj1.width, obj2.x + obj2.width)
  let y2 = Math.min(obj1.y + obj1.height, obj2.y + obj2.height)

  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
  }
}

const getArea = obj => {
  return obj.width * obj.height
}

const getCenterOffset = (obj1, obj2) => {
  let c1 = [obj1.x + obj1.width / 2, obj1.y + obj1.height / 2]
  let c2 = [obj2.x + obj2.width / 2, obj2.y + obj2.height / 2]

  return getDist(c1, c2)
}

const getDist = (point1, point2) => {
  
  if (!point1 || !point2) {
    return NaN
  }

  let [x1, y1] = point1
  let [x2, y2] = point2

  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
}

const getBestParent = (obj, parents) => {

  if (obj.type === COMPONENT) {
    return null
  }

  let matches = getObjectsInRect(parents, obj)

  let matchObjects = parents.filter(({ id }) => {
    return matches.indexOf(id) !== -1
  })

  let fullyContainedId = null

  let bestDist = Infinity
  let bestId = null

  for (let i = 0; i < matchObjects.length; i += 1) {
    let matchObj = matchObjects[i]
    let intersection = getIntersection(obj, matchObj)

    // Determine whether fully-contained
    if (getArea(intersection) === getArea(obj)) {
      fullyContainedId = matchObj.id
    }

    // Otherwise, calculate offset dist
    let dist = getCenterOffset(matchObj, obj)

    if (dist < bestDist) {
      bestId = matchObj.id
      bestDist = dist
    }
  }

  return fullyContainedId || bestId
}

const getAbsoluteBbox = (object, list, map) => {

  let path = map?.[object?.id]

  if (!path) {
    return object
  }

  let componentPath = subPath(path, 1)

  if (componentPath === path) {
    return object
  }

  let component = getObject(list, componentPath)

  return {
    id: object.id,
    x: object.x + component.x,
    y: object.y + component.y,
    width: object.width,
    height: object.height,
  }
}

const getParentId = (list, map, typeIndex, newObject) => {
  
  let listIds = []

  list.forEach(component => {
    listIds = listIds.concat((typeIndex[component.id] || {})[LIST] || [])
  })

  listIds = listIds.filter(
    listId =>
      listId !== newObject.id &&
      (!map[newObject.id] || !isChildPath(map[newObject.id] || '', map[listId]))
  )

  let parentId = getBestParent(
    newObject,
    list.concat(
      ...listIds.map(id => {
        return getAbsoluteBbox(getObject(list, map[id]), list, map)
      })
    )
  )

  return parentId
}

const nextPath = (path) => {
  const pieces = path.split('.');
  const nextPieces = pieces.slice(0, pieces.length - 1).concat([+pieces[pieces.length - 1] + 1]);
  return nextPieces.join('.');
}

const getInsertPath = function (objects, basePath) {

  const prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  const nest = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true; // basePath is invalid

  if (basePath && prefix && subPath(basePath, pathLength(prefix)) !== prefix) {
      basePath = prefix;
  } // basePath is not specified


  basePath = basePath || prefix;
  const target = getObject(objects, basePath);

  if (!target) {
      return '' + objects.length;
  }

  if (target.children && nest) {
      return basePath + '.' + target.children.length;
  }

  return nextPath(basePath);
}

const getInsertPosition = list => {
  let minY = Infinity
  let maxX = -Infinity

  if (list.length === 0) {
    return { x: 0, y: 0 }
  }

  list.forEach(screen => {
    if (screen.x + screen.width > maxX) {
      maxX = screen.x + screen.width
    }

    if (screen.y < minY) {
      minY = screen.y
    }
  })

  return {
    x: maxX + 100,
    y: minY,
  }
}

const DEFAULT_ZOOM = {
  scale: 1,
  offset: [0, 0],
}

const calculateZoom = (bbox) => {

  if (!bbox) {
    return DEFAULT_ZOOM
  }

  const { width, height, depth } = bbox

  const margin        = 100
  const leftPanel     = 64
  const rightPanel    = 0
  const topPanel      = 64
  const canvasWidth   = window.innerWidth - leftPanel - rightPanel
  const canvasHeight  = window.innerHeight - topPanel / 2
  const xScale        = Math.max(0, (canvasWidth - 2 * margin) / width)
  const yScale        = Math.max(0, (canvasHeight - 2 * margin) / height)
  let scale           = Math.min(xScale, yScale)

  const xOffset       = (window.innerWidth - leftPanel) / 2 - (width / 2 + bbox.x) * scale

  const yOffset       = window.innerHeight / 2 - (height / 2 + bbox.y) * scale + topPanel / 2

  scale               = Math.min(scale, 16)

  return {
    scale,
    offset: [xOffset, yOffset],
  }

}



// ----------------



const insert = (objects, newPath, ...newObjects) => {

  newObjects.reverse().forEach(newObject => {
    objects = immutabilityHelper(objects, buildUpdate(newPath, newObject, true))
  })
  
  return objects
}

const innerPerformCreate = ({
  object,
  list,
  id,
  newIds,
  state,
  parentId,
  map,
  path,
  isCopy,
  isRelative,
  setSelection,
  zoom,
  selection,
  objectIds,
  newObjects,
  changeIds = true
}) => {
  let newObject = emptyObject(object, list, changeIds);

  if (id && objects.length === 1) {
    newObject.id = id;
  }

  newIds.push(newObject.id);

  if (!parentId) {
    parentId = getParentId(list, map, state.typeIndex, newObject);
  }

  let newPath = getInsertPath(list, path, map[parentId]);

  if (isCopy && path) {
    newPath = path;
    path = nextPath(path);
  }

  if (newObject.type === COMPONENT && pathLength(newPath) > 1) {
    newPath = nextPath(subPath(newPath, 1));
  }

  if (parentId) {
    const component = getObject(list, subPath(map[parentId], 1));

    if (!isRelative) {
      newObject.x -= component.x;
      newObject.y -= component.y;
    }

    const { compound, points } = newObject;
    if (compound?.length > 0 || points?.length > 0) {
      let p = PathItem.create(compound || points);

      p.adjust(newObject.x, newObject.y);

      let newPoints = [];
      let newCompound = [];

      if (p instanceof CompoundPath) {
        newCompound = p.children.map(path => ({
          points: path.points,
          depth: path.depth
        }));
      } else {
        newPoints = p.points;
      }

      newObject = {
        ...newObject,
        compound: newCompound,
        points: newPoints
      };
    }

    newObject.depth = newObject.depth || component.depth / 2;
  } else {
    if (newObject.type !== COMPONENT) {
      return null;
    }

    if (!isRelative && newObject.x === undefined && newObject.y === undefined) {
      const position = getInsertPosition(list);
      newObject = { ...newObject, ...position };
    }

    if (setSelection) {
      zoom = calculateZoom(newObject);
    }
  }

  newObject = updateObjectWidth(newObject);
  list = insert(list, newPath, newObject);
  map = remapSiblings(list, map, newPath);
  list = updateParentBounds(list, map, newObject.id, null, resizeParent);
  list = updateOptions(list, map, newObject.id);

  if (newObject.type !== COMPONENT) {
    selection.push(newObject.id);
  }

  if (newObject.type === COMPONENT && newObject.objects?.length > 0) {
    const children = newObject.objects;
    newObject.objects = [];

    for (const child of children) {
      const result = innerPerformCreate({
        object: child,
        list,
        id: null,
        newIds,
        state,
        parentId: newObject.id,
        map,
        path,
        isCopy,
        isRelative: true,
        setSelection,
        zoom,
        selection: [],
        objectIds,
        newObjects,
        changeIds
      });

      if (result) {
        list = result.list;
        map = result.map;
      }
    }
  }

  if (newObject.type === GROUP && newObject.children?.length > 0) {
    const children = newObject.children;
    newObject.children = [];

    for (const child of children) {
      const updatedChild = {
        ...child,
        x: newObject.x + child.x,
        y: newObject.y + child.y,
        id: getId()
      };

      const result = innerPerformCreate({
        object: updatedChild,
        list,
        id: null,
        newIds,
        state,
        parentId: newObject.id,
        map,
        path,
        isCopy,
        isRelative: true,
        setSelection,
        zoom,
        selection: [],
        objectIds,
        newObjects,
        changeIds
      });

      if (result) {
        list = result.list;
        map = result.map;
      }
    }
  }

  objectIds.push(newObject.id);
  newObjects.push(newObject);

  return { list, parentId, map, path, zoom };
}


const getDefaults = props => {
  let result = {}

  if (props) {
    for (let prop of props) {
      if (prop.global && prop.default) {
        result[prop.name] = prop.default
      }
    }
  }

  return result
}

const setGlobalDefaults = (libraryGlobals, newObjects) => {

  for (let obj of newObjects) {
    if (obj.type !== LIBRARY_COMPONENT) {
      continue
    }

    let { libraryName, componentName, libraryComponentManifest: library } = obj

    let prevDefault = libraryGlobals?.[libraryName]?.[componentName]
    if (prevDefault) continue

    let result = getDefaults(library?.props)

    if (Array.isArray(library?.childComponents)) {
      for (let child of library?.childComponents) {
        let childResult = getDefaults(child?.props)

        if (Object.keys(childResult).length > 0) {
          result[child.name] = childResult
        }
      }
    }

    libraryGlobals = {
      ...libraryGlobals,
      [libraryName]: {
        ...libraryGlobals[libraryName],
        [componentName]: result,
      },
    }
  }

  return libraryGlobals
}

const getActiveComponent = (list, map, objectIds) => {

  let id = objectIds[0]

  if (id) {
    let screen = getObject(list, subPath(map[id], 1))

    return screen.id
  }

  return null
}

const performUpdate = (state, path, objects, id, parentId, isRelative = false, isCopy = false, setSelection = true, changeIds = true) => {
  if (!Array.isArray(id)) {
    id = [id];
  }

  let { list, map, zoom } = state;
  const paths = [];
  const componentIds = [];
  let parentPath = '';

  for (const currentId of id) {
    const currentPath = map[currentId];
    const obj = getObject(list, currentPath);

    if (obj.type === COMPONENT) {
      componentIds.push(currentId);
    } else {
      paths.push(currentPath);
    }

    list = remove(list, currentPath);
    list = updateParentBounds(list, map, currentId, null, resizeParent);
    list = updateParentOptions(list, map, currentId);

    delete map[currentId];
    map = remapSiblings(list, map, subPath(currentPath, pathLength(currentPath) - 1));

    const pieces = currentPath.split('.');
    parentPath = pieces.slice(0, pieces.length - 1).join('.');
    const siblings = parentPath ? getObject(list, parentPath)?.children || [] : list;

    const pathPrefix = parentPath ? `${parentPath}.` : '';

    siblings.forEach((sibling, position) => {
      map[sibling.id] = `${pathPrefix}${position}`;
    });
  }

  saveTouched(state.appId, list, map, null, paths, componentIds);

  state = updateIndices({
    ...state,
    list,
    map,
    selection: [],
    hoverSelection: [],
    shapeEditing: null
  });

  const objectIds = [];
  const selection = [];
  const newObjects = [];
  const newIds = [];

  objects = Array.isArray(objects) ? objects : [objects];

  if (!parentId && state.selectedParent) {
    parentId = state.selectedParent;
  }

  state = {
    ...state,
    selectedParent: null
  };

  for (const object of objects) {
    const result = innerPerformCreate({
      object,
      list,
      id,
      newIds,
      state,
      parentId,
      map,
      path: parentPath,
      isCopy,
      isRelative,
      setSelection,
      zoom,
      selection,
      objectIds,
      newObjects,
      changeIds
    });

    if (!result) {
      return state;
    }

    ({ list, map, path, zoom } = result);
  }

  const libraryGlobals = setGlobalDefaults(state.libraryGlobals, newObjects);

  saveTouched(
    state.appId,
    list,
    map,
    newObjects.map(o => o.id),
    undefined,
    undefined,
    libraryGlobals
  );

  const newObject = newObjects.length === 1 ? newObjects[0] : {};

  return [
    updateIndices({
      ...state,
      list,
      map,
      zoom,
      libraryGlobals,
      selection: setSelection ? selection : state.selection,
      activeComponent: getActiveComponent(list, map, selection),
      shapeEditing: [SHAPE, ELLIPSE].includes(newObject.type) && newObject.id
    }),
    ...newIds
  ];
}

const updateShapeAngle = (obj, changes) => {

  const { angle } = changes; const { compound, points } = obj

  let shape = PathItem.create()

  if (compound && compound.length > 0) {
    shape = PathItem.create(compound)
  } else if (points && points.length > 0) {
    shape = PathItem.create(points)
  }

  const diffAngle = obj.angle !== 0 ? angle - obj.angle : angle

  const unit = window.devicePixelRatio === 2 ? 2 : 1

  shape.rotate(diffAngle || 0, shape.getRotatePoint(obj.x / unit, obj.y / unit))

  const bounds = shape.bounds

  let newPoints = []; let newCompound = []

  if (shape instanceof CompoundPath) {

    newCompound = shape.children.map(path => ({
      points: path.points,
      depth: path.depth
    }))

  } else {
    newPoints = shape.points
  }

  return {
    points: newPoints,
    compound: newCompound,
    angle,
    x: bounds.left,
    y: bounds.top,
    width: bounds.width,
    height: bounds.height
  }
}

export function computed_objects(state, action) {

  let { list } = state

  const { objects } = action

  for (const object of objects) {

    const { id }    = object
    const path      = state.map[id]
    const oldObject = getObject(state.list, path) || {}
    const changes   = evaluate(object, oldObject)

    if(changes.width < 100){
      changes.width = 100
    }

    if(changes.height < 100){
      changes.height = 100
    }

    let newObject   = { ...oldObject, ...changes }

    if (oldObject.type !== COMPONENT) {
      newObject = updateObjectWidth(newObject); newObject = translateChildren(newObject, oldObject)
    }

    if (oldObject.type === LIBRARY_COMPONENT) {
      newObject = updateComponentBounds(newObject)
    }

    if (oldObject.type === RECTANGLE) {

      const rect = createRectangle(newObject)

      newObject = {
        ...newObject,
        points: rect.points
      }

    }

    if ([SHAPE, ELLIPSE, RECTANGLE].includes(oldObject.type)) {

      const resized = updateShapeBounds(oldObject, changes)

      newObject = {
        ...newObject,
        ...resized
      }

    }

    if ('orient' in object && oldObject.orient !== object.orient) {
      const orient = oldObject.orientations.find(x => x.id === object.orient);

      if (orient && orient.data) {
        changes.objects = orient.data.map(d => {
          if (d.s === "POLYGON") {
            return {
              x: oldObject.x + d.x,
              y: oldObject.y + d.y,
              width: d.w,
              height: d.h,
              depth: d.d,
              points: d.p.map(pt => ({
                point: [pt[0] + oldObject.x, pt[1] + oldObject.y]
              })),
              type: SHAPE
            };
          }
          return null;
        }).filter(Boolean);

        const updatedObject = { ...oldObject, ...changes, children: [] };

        return performUpdate(
          state,
          path,
          updatedObject,
          id,
          updatedObject.parentId,
          false,
          false,
          true
        )[0];
      }
    }

    if ('angle' in object && oldObject.angle !== object.angle) {
      if ([SHAPE, ELLIPSE, RECTANGLE].includes(oldObject.type)) {
        const resized = updateShapeAngle(oldObject, changes);
        newObject = {
          ...newObject,
          ...resized
        };
      }
    }



    list = update(list, path, newObject)
    list = updateParentBounds(list, state.map, id, null, resizeParent)
    list = updateOptions(list, state.map, id)

  }

  saveTouched(
    state.appId,
    list,
    state.map,
    objects.map(obj => obj.id)
  )

  return {
    ...state,
    list
  }

}