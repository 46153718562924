import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
    COMPONENT_INSTANCE,
    ELLIPSE,
    GROUP,
    IMAGE,
    IMAGE_UPLOAD,
    LABEL,
    LIBRARY_COMPONENT,
    LINE,
    RECTANGLE,
    SECTION,
    SHAPE,
} from 'common/constants'

import {scale, scaleValue} from '../../../utils/zoom'
import {positionObjects} from '../../../ducks/editor/objects'
import {beginDrag} from '../../../ducks/editor/positioning'
import {setEditingText} from '../../../ducks/editor/textEditing'
import {setEditingShape} from '../../../ducks/editor/shapeEditing'

import {setCanvasHover, setExpandSelection, setSelection,} from '../../../ducks/editor/selection'

import Label from './Label'
import Section from './Section'
import Line from './Line'
import Ellipse from './Ellipse'
import Shape from './Shape'
import Image from './Image'
import Rectangle from './Rectangle'

import Group from './Group'

import ComponentInstance from './ComponentInstance'
import LibraryComponent from './LibraryComponent'


class CanvasObject extends Component {

    static contextTypes = {
        editable: PropTypes.bool,
        getSelection: PropTypes.func,
    }

    state = {error: null}

    handleMouseEnter = e => {

        let { object, setCanvasHover, fake } = this.props

        let { editable } = this.context

        if (!editable || fake) {
            return
        }

        setCanvasHover(object.id)

        if (!editable || fake) {
            return
        }

        setSelection(object.id, false, false)

    }

    handleSelect = (shiftKey, cmdKey) => {

        let {object, setSelection, fake} = this.props
        let {editable} = this.context

        if (!editable || fake) {
            return
        }

        setSelection(object.id, shiftKey, !cmdKey)
    }

    handleExpand = () => {
        let {editable} = this.context
        let {object, setExpandSelection, fake} = this.props

        if (!editable || fake) {
            return
        }

        setExpandSelection(object.id)
    }

    handleEdit = () => {
        let {object, setEditingText, setEditingShape, fake} = this.props
        let {editable} = this.context

        if (!editable || fake) {
            return
        }

        if (object.type === LABEL) {
            setEditingText(object.id)
        }

        if (object.type === SHAPE) {
            setEditingShape(object.id)
        }
        if (object.type === ELLIPSE) {
            setEditingShape(object.id)
        }
        /*    if (object.type === ELLIPSE) {
              setEditingEllipse(object.id)
            }*/

    }

    shouldComponentUpdate(newProps) {
        let {zoom} = this.props

        let criticalProps = [
            'object',
            'textEditing',
            'shapeEditing',
            'hideShadows',
            'branding',
        ]

        for (let propName of criticalProps) {
            // eslint-disable-next-line react/destructuring-assignment
            if (newProps[propName] !== this.props[propName]) {
                return true
            }
        }

        if (zoom.scale !== newProps.zoom.scale) {
            return true
        }

        return false
    }

    componentDidCatch(error, info) {
        let {object} = this.props

        // This will make it get caught by sentry
        window.setTimeout(() => {
            let newError = new Error(
                `Component Error [${object && object.type}]: ${error.message}`
            )

            newError.stack = [
                newError.stack.split('\n').slice(0, 2),
                error.stack,
            ].join('\n')


                console.error(newError)

        }, 0)

        this.setState({error})
    }

    render() {
        let {
            object,
            zoom,
            transformZoom,
            hideShadows,
            beginDrag,
            branding,
            angle,
            rotateWidth,
            rotateHeight,
            parent,
            fake
        } = this.props

        let {error} = this.state

        if (error) {
            return null
        }

        let [xScaled, yScaled] = scale([object.x, object.y], zoom)
        let widthScaled = scaleValue(object.width, zoom)
        let heightScaled = scaleValue(object.height, zoom)

        let {children, ...withoutChildren} = object
        angle = angle || object.angle
        let childProps = {
            ...withoutChildren,
            angle,
            rotateWidth,
            rotateHeight,
            parent,
            xScaled,
            yScaled,
            widthScaled,
            heightScaled,
            zoom,
            transformZoom,
            hideShadows,
            branding,
            onPosition: beginDrag,
            onExpand: this.handleExpand,
            onSelect: this.handleSelect,
            onEdit: this.handleEdit,
            object: withoutChildren,
            fake
        }

        if (object.hidden) {
            return null
        }

        switch (object.type) {
            case LABEL:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <Label {...childProps} />
                    </g>
                )
            case SECTION:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <Section {...childProps} />
                    </g>
                )
            case RECTANGLE:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <Rectangle {...childProps} />
                    </g>
                )
            case LINE:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <Line {...childProps} />
                    </g>
                )
            case ELLIPSE:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <Ellipse {...childProps} />

                    </g>
                )
            case SHAPE:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <Shape {...childProps} />
                    </g>
                )
            case IMAGE:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <Image {...childProps} />
                    </g>
                )
            case GROUP:
                return (
                    <Group object={object} >

                        {object.children.map((obj, index) => (
                            <ConnectedCanvasObject
                                key={`${obj.id}-${index}`}
                                object={obj}
                                parent={object}
                                zoom={zoom}
                                angle={object.angle}
                                rotateWidth={widthScaled}
                                rotateHeight={heightScaled}
                                transformZoom={transformZoom}
                                hideShadows={hideShadows}
                                branding={branding}
                            />
                        ))}

                    </Group>
                )
            case IMAGE_UPLOAD:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <ImageUpload {...childProps} />
                    </g>
                )
            case COMPONENT_INSTANCE:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <ComponentInstance {...childProps} />
                    </g>
                )
            case LIBRARY_COMPONENT:
                return (
                    <g
                        onMouseEnter={this.handleMouseEnter}
                        onTouchStart={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <LibraryComponent {...childProps} />

                    </g>
                )

        }

        return null
    }
}

const ConnectedCanvasObject = connect(null, {
    setExpandSelection,
    setSelection,
    positionObjects,
    setCanvasHover,
    setEditingShape,

    setEditingText,
    beginDrag,
})(CanvasObject)

export default ConnectedCanvasObject
