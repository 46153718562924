import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import {getBestParent} from 'utils/geometry'
import {scale, scaleValue, unScale} from 'utils/zoom'
import {getSnapValue} from 'utils/snapping'
import { getApp } from "ducks/apps/selectors";
import {createObject, getCurrentAppId, selectObjects} from 'ducks/editor/objects'

import {getXGrid, getYGrid, resetSnaps, setXSnap, setYSnap,} from 'ducks/editor/snapping'

import {getStartPosition, resetTool} from 'ducks/editor/tools'
import 'styles/AddLibraryComponent.css'
import {GROUP, SHAPE} from "common/constants";
import {PathItem} from "utils/vector";
import {getActiveStack} from "ducks/editor/stack";
import CanvasObject from "./CanvasObject";

const MIN_RADIUS_SQUARED = 20 ** 2


function centerObject(x, y, obj) {
    x -= Math.floor(obj.width / 2)
    y -= Math.floor(obj.height / 2)

    return [x, y]
}

function applyOffset(x, y, props) {


    return [x, y]
}

class AddShapeComponent extends Component {
    constructor(props) {
        super(props)

        let startPosition = props.startPosition
            ? unScale(props.startPosition, props.zoom)
            : [0, 0]


        let {orientations} = props.options

        const [curr] = orientations

        let [x, y] = unScale(startPosition, props.zoom)
        let [x2, y2] = centerObject(x, y, curr)
        let [x3, y3] = applyOffset(x2, y2, props)

        this.state = {
            x: x3,
            y: y3,
            mouseOver: !!props.startPosition,
            xSnapped: x3,
            ySnapped: y3,
            widthSnapped: 0,
            heightSnapped: 0,
        }
    }

    handleMouseUp = e => {
        let {startPosition} = this.props
        let [x, y] = startPosition
        let distSquared = (e.clientX - x) ** 2 + (e.clientY - y) ** 2

        if (distSquared < MIN_RADIUS_SQUARED) {
            return
        }

        this.handleMouseDown(e)
    }

    handleMouseDown = e => {

        let {xSnapped, ySnapped, widthSnapped, heightSnapped} = this.state

        let {createObject, resetTool, resetSnaps, options, options: {orientations}, zoom} = this.props
        let compound = []
        let [curr] = orientations

        if (curr.data) {

            curr.compound = curr.data.map(d => {

                if (d.s === "POLYGON") {

                    let p = PathItem.create(d.p)

                    p.setClosed(true)
                    p.reorient(true, true);
                    //p.adjust(xSnapped + d.x, ySnapped + d.y)

                   var  pp = p.points.map(pt => {
                        return pt.point
                    })

                    pp.push(true)
                    return {points: pp, depth: d.d }
                    /*return {
                        x: d.x,
                        y: d.y,
                        width: d.w,

                        height: d.h,
                        depth: d.d,
                        points: p.points.map(pt => {
                            return pt.point
                        }),
                        type: SHAPE
                    }*/

                }
            })
        }

        createObject({
            id: curr.id,
            //children: curr.children,
            compound: curr.compound,
            name: curr.name,
            orient: curr.id,
            orientations: orientations,
            x: xSnapped,
            y: ySnapped,
            width: widthSnapped || curr.width,
            height: heightSnapped || curr.height,
            depth: curr.depth,
            type: SHAPE
        })

        resetTool()
        resetSnaps()
    }

    handleMouseMove = e => {
        let {zoom, options: {orientations}} = this.props


        const [curr] = orientations

        let [x, y] = unScale([e.clientX, e.clientY], zoom)


        let [x2, y2] = centerObject(x, y, curr)
        let [x3, y3] = applyOffset(x2, y2, this.props)

        let parent = this.getBestParent(x3, y3)

        let snap = this.calculateSnappedPosition(x, y, parent)


        let [xSnapped, ySnapped] = snap

        this.setState({
            mouseOver: true,
            x: x3,
            y: y3,
            xSnapped,
            ySnapped,

        })
    }

    handleMouseLeave = () => {
        this.setState({mouseOver: false})
    }

    getBestParent = (xArg, yArg) => {
        let {x, y} = this.state
        x = xArg || x
        y = yArg || y

        let {options, screens, options: {orientations}, app} = this.props

        const [curr] = orientations

        let obj = {...curr, x, y}

        let parentId = getBestParent(obj, screens)

        return screens.filter(s => s.id === parentId)[0]
    }

    calculateGridSnapPosition = (x, y) => {
        let {options, xGrid, yGrid, setXSnap, setYSnap, zoom, options: {orientations}} = this.props

        const [curr] = orientations

        let {width, height} = curr

        if (!xGrid || !yGrid) {
            return [x, y, width, height]
        }

        let xDiff = 0
        let yDiff = 0

        let xCoords = {
            left: x,
            center: x + width / 2,
            right: x + width,
        }

        let yCoords = {
            top: y,
            center: y + height / 2,
            bottom: y + height,
        }

        let xSnap = getSnapValue(xGrid, xCoords, zoom)
        let ySnap = getSnapValue(yGrid, yCoords, zoom)

        if (xSnap) {
            let key = Object.keys(xSnap)[0]
            xDiff = xSnap[key] - xCoords[key]
            setXSnap(xSnap[key])
        } else {
            setXSnap(null)
        }

        if (ySnap) {
            let key = Object.keys(ySnap)[0]
            yDiff = ySnap[key] - yCoords[key]
            setYSnap(ySnap[key])
        } else {
            setYSnap(null)
        }

        return [Math.round(x + xDiff), Math.round(y + yDiff), width, height]
    }

    calculateSnappedPosition = (x, y, parent) => {
        return this.calculateGridSnapPosition(x, y)
    }

    renderComponent = () => {
        let {zoom, options} = this.props

        const [curr] = options.orientations
        let {xSnapped, ySnapped} = this.state
        let [x, y] = scale([xSnapped, ySnapped], zoom)
        let {width, height} = curr

    let unScaledWidth =  width
    let unScaledHeight =  height

        width = scaleValue(unScaledWidth, zoom)
        height = scaleValue(unScaledHeight, zoom)

        let childZoom = {...zoom, offset: [0, 0]}


        if (curr.data) {
            curr.children = curr.data.map(d => {
                if (d.s === "POLYGON") {
                    let p = PathItem.create(d.p)
                    p.adjust(d.x, d.y)
                    p.setClosed(true)
                    /*p.scale(zoom.scale)*/
                    return {
                        x: d.x,
                        y: d.y,
                        width: d.w,
                        height: d.h,
                        depth: d.d,
                        points: p.points.reverse().map(pt => {
                            return pt.point
                        }),
                        type: SHAPE
                    }

                }
            })
        }

        let object = {

            id: curr.id,
            children: curr.children,
            name: curr.name,
            width: unScaledWidth,
            height: unScaledHeight,
            depth: curr.depth,
            x: 0,
            y: 0,
            type: GROUP
        }


        return (
            <g transform={`translate(${x}, ${y})`} opacity={0.5}>
                <CanvasObject object={object} zoom={childZoom}/>
                <rect
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    stroke="none"
                    fill="none"
                />
            </g>


        )
    }

    render() {
        let {mouseOver} = this.state

        let width = window.innerWidth
        let height = window.innerHeight

        return (
            <g className="add-component-instance">
                {mouseOver ? this.renderComponent() : null}
                <rect
                    onMouseDown={this.handleMouseDown}
                    onMouseUp={this.handleMouseUp}
                    onMouseMove={this.handleMouseMove}
                    onMouseLeave={this.handleMouseLeave}
                    onTouchMove={this.handleMouseMove}
                    onMouseEnter={this.handleMouseMove}
                    x={0}
                    y={0}
                    width={width}
                    height={height}
                    className="add-component-backdrop"
                />
            </g>
        )
    }
}

const mapStateToProps = (state, {match, options}) => ({
    app: getApp(state, getCurrentAppId(state)),
    screens: selectObjects(state),
    xGrid: getXGrid(state),
    yGrid: getYGrid(state),
    startPosition: getStartPosition(state),
    activeStack: getActiveStack(state),

})

export default withRouter(
    connect(mapStateToProps, {
        createObject,
        resetTool,
        setXSnap,
        setYSnap,
        resetSnaps,
    })(AddShapeComponent)
)
