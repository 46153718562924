import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {adjustZoom, getTransform} from 'utils/zoom'

import {setCanvasHover, setSelection} from '../../../ducks/editor/selection'
import {beginDrag} from '../../../ducks/editor/positioning'
import {getActiveStack, getDividers, updateDivider} from '../../../ducks/editor/stack'

import {
    createObject,
    getCurrentAppId,
    getPlatform,
    getSelectedParent,
    getYOffset,
    selectObjects,
    updateObjects,
} from '../../../ducks/editor/objects'

import { getApp } from "ducks/apps/selectors";

import {getImage, getPath} from "../../../ducks/trace";
import EmptyState from "../../Shared/EmptyState";
import {PathItem, Rectangle, Segment} from "../../../utils/vector";
import {Panel} from "./Panel";
import {COMPONENT} from "../../../common/constants";
import {createName} from "../../../utils/naming";
import {pxToUnit} from "../../../utils/unit";

export const DEFAULT_SIZE_TRACE_CANVAS = {
    width: 1000,
    height: 1000
}

class Canvas extends Component {

    static contextTypes = {
        editable: PropTypes.bool,
    }

    constructor(props) {
        
        super(props)

        this.canvasRef = React.createRef()

        this.state = {
            activeLayerId: '',
            clicked: false,
            resizeStartPoint: [],
            components: props.components
        }

    }

    handleMouseDown = (e, activeLayerId) => {
        let resizeStartPoint = [e.clientX, e.clientY]
        this.setState({clicked : true, resizeStartPoint, activeLayerId})
    }

    handleMouseUp = e => {
        let {components} = this.state
        let {updateObjects} = this.props
        this.setState({clicked : false})
        const arr = Object.values(components)

        const updated = []
        arr.forEach((o) => {
            updated.push({
                id: o.id,
                depth: o.depth
            })
        })
        updateObjects(updated)

    }

    handleMouseMove = (e, zoom )=> {
        let { activeLayerId, clicked, resizeStartPoint} = this.state

        let [startX, startY] = resizeStartPoint

        let newX = e.clientX
        let newY = e.clientY

        let diffY =   Math.round((newY - startY) / zoom.scale)



        let {components} = this.state
        let {createObject, updateObjects, onSuccess, opts,  app: {width, height, depth}} = this.props
        let recalculate = []
        let boxDepth = 0



        if (activeLayerId && clicked) {
            const arr = Object.values(components).sort(function (a, b) {
                return a.order - b.order;
            })

            arr.forEach((o) => {
                boxDepth += o.depth
            })
            const current = arr.findIndex((el) => el.id === activeLayerId)
            if (current !== -1) {

               let next = arr[current+1]
                const active =  arr[current]
                let available =  Math.max(0, depth,  next && next.depth || 0 + active && active.depth || 0)

                const newDepth = Math.max(0, +components[ active.id].depth + diffY)

                if (newDepth <= available) {
                    components[ active.id].depth = newDepth
                    next ? components[next.id].depth -=  diffY : null
                }



                let resizeStartPoint = [e.clientX, e.clientY]
                this.setState({components, resizeStartPoint})
            }


           // recalculate.length > 0 && updateObjects(recalculate)
        }


    }

    getDefaultName = () => {
        let {list} = this.props

        return createName(COMPONENT, null, list)
    }

    handleSplit = (zoom )=> {

        let { activeLayerId, clicked, resizeStartPoint, components} = this.state
        let {createObject, updateObjects, onSuccess, opts,  app: {width, height, depth}, list} = this.props




        if (activeLayerId) {
            const arr = Object.values(components).sort(function (a, b) {
                return a.order - b.order;
            })

            const current = arr.findIndex((el) => el.id === activeLayerId)
            if (current !== -1) {


                const active =  arr[current]
                const half = components[ active.id].depth/2
                components[active.id].depth =half

                let order = ++active.order

                createObject(
                    {
                        width:width,
                        height: height,
                        depth: half,
                        backgroundColor: '@background',
                        name: this.getDefaultName(),
                        type: COMPONENT,
                        order: order
                    },
                    null,
                    !zoom
                )

               const updated = [{
                   id: active.id,
                   depth: half
               }]


                const reorder = arr.slice(current + 1)

                reorder.forEach((obj) => {
                        updated.push({
                            id: obj.id,
                            order: ++order
                        })
                }

                )
                updateObjects(updated)
                //this.setState({components})
            }


            // recalculate.length > 0 && updateObjects(recalculate)
        }


    }

    handleMouseLeave = () => {
        this.setState({mouseOver: false})
    }

    handleLayerClick = (layerId) => {
        this.setState({activeLayerId: layerId})
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (prevProps.components !== this.props.components) {
            this.setState({ components: this.props.components });
        }
    }

    componentWillUnmount() {
        
    }
    
    render() {

        let {
            image,
            path
        } = this.props

        let {app, setLayersHover, setSelection} = this.props

        const {components, activeLayerId} = this.state

        if (!components) {
            return <EmptyState>Пусто</EmptyState>
        }



        const {app: {width, height, depth}} = this.props


        const rect = new Rectangle(50, 50, width, depth);

        const bl = rect.getBottomLeft(true),
            tl = rect.getTopLeft(true),
            tr = rect.getTopRight(true),
            br = rect.getBottomRight(true)
        ;

        let segments = [];

        segments = [
            new Segment(bl),
            new Segment(tl),
            new Segment(tr),
            new Segment(br)
        ];



        const coreFoam = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
            return new Segment(point, handleIn, handleOut)
        }))

        coreFoam.setClosed(true)
        const bbox = coreFoam.bounds.bbox()

        const zoom = adjustZoom({
                x: 0,
                y: 0,
                width: bbox.width + 100,
                height: bbox.height + 100,
            }
        )

        let merge = 50

        const layers = Object.values(components).sort(function (a, b) {
            return a.order - b.order;
        }).map((o, index) => {
            const rect = new Rectangle(50, merge, o.width, o.depth);

            const bl = rect.getBottomLeft(true),
                tl = rect.getTopLeft(true),
                tr = rect.getTopRight(true),
                br = rect.getBottomRight(true)
            ;

            let segments = [];

            segments = [
                new Segment(bl),
                new Segment(tl),
                new Segment(tr),
                new Segment(br)
            ];

            const g = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
                return new Segment(point, handleIn, handleOut)
            }))

            g.setClosed(true)
            merge += o.depth

            const handler = new Rectangle(50, merge, o.width, 24);

            const hbl = handler.getBottomLeft(true),
                htl = handler.getTopLeft(true),
                htr = handler.getTopRight(true),
                hbr = handler.getBottomRight(true)
            ;

            const handlerPoint = [
                new Segment(hbl),
                new Segment(htl),
                new Segment(htr),
                new Segment(hbr)
            ]
            const handlerPath = PathItem.create(handlerPoint.map(({point, handleIn, handleOut}) => {
                return new Segment(point, handleIn, handleOut)
            }))

            handlerPath.setClosed(true)
            merge += 24

            return (
                <g 
                    key={o.id}
                    onClick={(e) => {
                        this.handleLayerClick(o.id)
                    }} 
                    className={o.id === activeLayerId ? 'active' : ''}
                >

                    <g>
                        <path className={'layer-foam'} strokeWidth={2} fill={"transparent"} stroke={"#ff0000"} d={g.getPathData()}/>
                        <line className="dimension layer-depth top horizontal" x1={tl.x - 50} y1={tl.y} x2={tl.x} y2={tl.y}/>
                        <line className="dimension layer-depth top arrow left" x1={tl.x - 25} y1={tl.y}
                              x2={tl.x - 25 - 6} y2={tl.y + 15}/>
                        <line className="dimension layer-depth top arrow right" x1={tl.x - 25} y1={tl.y}
                              x2={tl.x - 25 + 6} y2={tl.y + 15}/>
                        <line className="dimension layer-depth line" x1={bl.x - 25} y1={tl.y} x2={bl.x - 25} y2={bl.y}/>
                        <line className="dimension layer-depth bottom horizontal" x1={bl.x - 50} y1={bl.y} x2={bl.x}
                              y2={bl.y}/>

                        <line className="dimension layer-depth bottom arrow left" x1={bl.x - 25} y1={bl.y}
                              x2={bl.x - 25 - 6} y2={bl.y - 15}/>
                        <line className="dimension layer-depth bottom arrow right" x1={bl.x - 25} y1={bl.y}
                              x2={bl.x - 25 + 6} y2={bl.y - 15}/>

                        <text filter="url(#white-background)" className="dimension layer-depth" x={tl.x - 50}
                              y={bl.y - o.depth / 2} fontSize="24px">{pxToUnit(o.depth) }
                        </text>


                    </g>
                    {  index < Object.values(components).length -1  && <g onMouseDown={(e) => {this.handleMouseDown(e ,o.id,  zoom)}}

                      >
                        <path strokeWidth={2} fill={"transparent"} stroke={"#transparent"} className="trace-path"
                              d={handlerPath.getPathData()}/>
                        <text dx="-20" dy="5" className="resizing-icon noselect" x={htr.x / 2} y={hbr.y - 12}></text>
                        <text dx="5" dy="5" className="resizing-icon noselect" x={htr.x / 2} y={hbr.y - 12}></text>
                    </g>}
                </g>
            )

        })

        let transform = getTransform(zoom, true, 50, 50)
        let styles = {transform}


        return (
            <div className={'layer-view-view'} onMouseUp={this.handleMouseUp}>
                <svg
                    className="layer-view-canvas-objects"
                    width={width + 100}
                    height={height + 100}
                    style={styles}
                >
                    <g  onMouseUp={this.handleMouseUp}  onMouseMove={(e) => {this.handleMouseMove(e, zoom)}}>
                    <defs>
                        <filter id="white-background">
                            <feFlood floodColor="white"/>
                            <feComposite in="SourceGraphic"/>
                        </filter>
                        <filter id="glow">
                            <feGaussianBlur result="coloredBlur" stdDeviation="6" className="blur"/>
                            <feMerge>
                                <feMergeNode in="coloredBlur"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g>


                        <path strokeWidth={2} fill={"transparent"} stroke={"#ff0000"} className="trace-path"
                              d={coreFoam.getPathData()}/>
                        <line className="dimension left-vertical" x1={tl.x} y1="0" x2={tl.x} y2="43"/>
                        <line className="dimension left-arrow-top" x1={tl.x} y1="20" x2={tl.x + 15} y2="14"/>
                        <line className="dimension left-arrow-bottom" x1={tl.x} y1="20" x2={tl.x + 15} y2="27"/>
                        <line className="dimension line" x1={tl.x} y1="20" x2={tr.x} y2="20"/>
                        <line className="dimension right-arrow-top" x1={tr.x} y1="20" x2={tr.x - 15} y2="14"/>
                        <line className="dimension right-arrow-bottom" x1={tr.x} y1="20" x2={tr.x - 15} y2="27"/>
                        <line className="dimension right-vertical" x1={tr.x} y1="0" x2={tr.x} y2="43"/>
                        <text filter="url(#white-background)" dx="0" dy="10" className="dimension upper-width"
                              x={tr.x / 2}
                              y={tr.y - 25}
                              fontSize="24px">{pxToUnit(width) }
                        </text>

                        <line className="dimension left-vertical" x1={bl.x} y1={bl.y} x2={bl.x} y2={bl.y + 43}/>
                        <line className="dimension left-arrow-top" x1={bl.x} y1={bl.y + 20} x2={bl.x + 15}
                              y2={bl.y + 14}/>
                        <line className="dimension left-arrow-bottom" x1={bl.x} y1={bl.y + 20} x2={bl.x + 15}
                              y2={bl.y + 27}/>
                        <line className="dimension line" x1={bl.x} y1={bl.y + 20} x2={br.x} y2={br.y + 20}/>

                        <line className="dimension right-arrow-top" x1={br.x} y1={br.y + 20} x2={br.x - 15}
                              y2={br.y + 14}/>
                        <line className="dimension right-arrow-bottom" x1={br.x} y1={br.y + 20} x2={br.x - 15}
                              y2={br.y + 27}/>
                        <line className="dimension right-vertical" x1={br.x} y1={br.y} x2={br.x} y2={br.y + 43}/>
                        <text filter="url(#white-background)" dx="0" dy="10" className="dimension upper-width"
                              x={br.x / 2}
                              y={br.y + 20}
                              fontSize="24px">{pxToUnit(width) }
                        </text>

                        <g>
                            <line className="dimension layer-depth top horizontal" x1={tr.x + 50} y1={tr.y} x2={tr.x}
                                  y2={tr.y}/>
                            <line className="dimension layer-depth top arrow left" x1={tr.x + 25} y1={tr.y}
                                  x2={tr.x + 25 - 6} y2={tr.y + 15}/>
                            <line className="dimension layer-depth top arrow right" x1={tr.x + 25} y1={tr.y}
                                  x2={tr.x + 25 + 6} y2={tr.y + 15}/>
                            <line className="dimension layer-depth line" x1={tr.x + 25} y1={tr.y} x2={br.x + 25}
                                  y2={br.y}/>

                            <line className="dimension layer-depth bottom horizontal" x1={br.x + 50} y1={br.y} x2={br.x}
                                  y2={br.y}/>
                            <line className="dimension layer-depth bottom arrow left" x1={br.x + 25} y1={br.y}
                                  x2={br.x + 25 - 6} y2={br.y - 15}/>
                            <line className="dimension layer-depth bottom arrow right" x1={br.x + 25} y1={br.y}
                                  x2={br.x + 25 + 6} y2={br.y - 15}/>

                            <text filter="url(#white-background)" className="dimension layer-depth" x={tr.x + 50}
                                  y={br.y - depth / 2} dx={-100} fontSize="24px">{pxToUnit(depth) }
                            </text>

                        </g>
                    </g>

                    {layers}
                    </g>
                </svg>

                <Panel activeLayer={activeLayerId} onSplit={()=>{this.handleSplit(zoom)}}></Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const selectedParent = getSelectedParent(state)
    const app = getApp(state, getCurrentAppId(state))
    const image = getImage(state)
    const path = getPath(state)
    const components = app && app.components
    return {
        selectedParent,
        app,
        dividers: getDividers(state),
        yOffset: getYOffset(state),
        platform: getPlatform(state),
        activeStack: getActiveStack(state),
        image,
        components,
        path,
        list: selectObjects(state),

    }
}

export default connect(mapStateToProps, {
    setCanvasHover,
    setSelection,
    updateDivider,
    beginDrag,
    createObject,
    updateObjects
})(Canvas)