import chroma from 'chroma-js'

export const GREYS = [
  { title: 'Black', color: '#000000' },
  { title: 'Grey #1', color: '#212121' },
  { title: 'Grey #2', color: '#424242' },
  { title: 'Grey #3', color: '#757575' },
  { title: 'Grey #4', color: '#9E9E9E' },
  { title: 'Grey #5', color: '#E0E0E0' },
  { title: 'Grey #6', color: '#F5F5F5' },
  { title: 'White', color: '#FFFFFF' },
]

export const defaultBranding = {
  primary: '#00A898',
  secondary: '#FFC00E',
  background: '#FFFFFF',
  text: '#424242',
  fonts: {
    body: {
      family: 'default',
    },
    heading: {
      family: 'default',
    },
  },
}

function brighten(color) {
  return getRGBA(chroma(color).brighten().rgba())
}

function darken(color) {
  return getRGBA(chroma(color).darken().rgba())
}

export function getPresetsForBranding(branding = defaultBranding) {
  return [
    ...GREYS,
    { title: 'Primary', color: branding.primary },
    { title: 'Primary Dark', color: darken(branding.primary) },
    { title: 'Primary Light', color: brighten(branding.primary) },
    { title: 'Secondary', color: branding.secondary },
    { title: 'Secondary Dark', color: darken(branding.secondary) },
    { title: 'Secondary Light', color: brighten(branding.secondary) },
    { title: 'Default Text', color: branding.text },
    { title: 'Default Background', color: branding.background },
  ]
}

export function normalizeColor(value, branding = defaultBranding, ctx = {}) {
  
  if (!value || value[0] !== '@') {
    return value
  }

  if (value.startsWith('@contrast:')) {
    const sibling = value.substr(10)

    return contrastWithBackground(normalizeColor(ctx[sibling], branding))
  }

  const match = /^@(primary|secondary|background|text)(Light|Dark)?$/.exec(
    value
  )

  if (!match) {
    return value
  }

  const color = branding[match[1]]

  switch (match[2]) {
    case 'Light':
      return brighten(color)
    case 'Dark':
      return darken(color)
    default:
      return color
  }
}

export const dynamicColorNames = {
  '@primary': 'Primary',
  '@primaryDark': 'Primary Dark',
  '@primaryLight': 'Primary Light',
  '@secondary': 'Secondary',
  '@secondaryDark': 'Secondary Dark',
  '@secondaryLight': 'Secondary Light',
  '@text': 'Default Text',
  '@background': 'Default Background',
}

export function normalizeColorName(value) {
  if (!value || !value.startsWith('@')) {
    return value
  }

  if (value.startsWith('@contrast')) {
    return 'Auto'
  }

  if (value.startsWith('transparent')) {
    return '#FFFFFF00'
  }

  return dynamicColorNames[value] || value
}

export const getBrandingFromHex = (val, branding = defaultBranding) => {
  let color = getRGBA(val.rgb)

  for (const prop of Object.keys(branding)) {
    let brandColor = branding[prop]
    let includeVariants = ['primary', 'secondary'].includes(prop)

    // if (!brandColor?.startsWith('#')) continue

    if (color === getRGBA(brandColor)) {
      return `@${prop}`
    } else if (includeVariants && color === darken(brandColor)) {
      return `@${prop}Dark`
    } else if (includeVariants && color === brighten(brandColor)) {
      return `@${prop}Light`
    }
  }

  return color
}

export const getRandom = (color) => {

  if (color === '@background') {

    color = 'rgba(255,255,255, 1)'
    return color
  }

  if (color) {
    return color
  }

  //if (color === 'transparent') {
    color = 'rgba(207,207,207, .85)'
    return color
  //}

  //let random = chroma.random().alpha()

  //return random.css()
}

export const getRGBA = color => {
  if (typeof color === 'string') {
    color = chroma(color).rgba()
  }

  if (Array.isArray(color)) {
    return `rgba(${color.join(', ')})`
  }

  let c = color

  return `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a})`
}

export function contrastWithBackground(bgColor) {
  if (!bgColor) {
    return
  }

  if (bgColor === 'transparent') {
    bgColor = 'rgba(0, 0, 0, 0)'
  }

  let alpha = chroma(bgColor).alpha()
  bgColor = chroma(bgColor).alpha(0.85)
  let adjustedColor = chroma.mix('#fff', bgColor, Math.sqrt(alpha))

  return chroma.contrast(adjustedColor, '#fff') >= 2.5 ? '#fff' : '#000'
}

export const colors = [
  'darkPink',
  'orange',
  'yellow',
  'green',
  'teal',
  'purple',
]
