import {COMPONENT} from 'common/constants'

import {
    deepMap,
    getId,
    getObject,
    nextPath,
    pathLength,
    remapSiblings,
    remove,
    removeChildren,
    subPath,
    translateChildren,
    update,
    updateParentBounds,
} from 'common/utils'

import {resetLibraryBindingIds} from 'utils/libraries'
import {getSnapValue} from 'utils/snapping'
import {unScale} from 'utils/zoom'
import {getAbsoluteBbox, getDist} from 'utils/geometry'
import {saveTouched} from 'utils/saving'
import {changeObjectParent, getParentIds} from 'utils/positioning'

import {changeChildIds} from 'utils/copying'

import {getParentId, performCreate, resizeParent} from './objects'
import {moveShape} from "../../utils/shapes";

import { computed_drag } from "./positioning_actions/computed_drag"



export const BEGIN_DRAG = Symbol('BEGIN_DRAG')
export const END_DRAG = Symbol('END_DRAG')
export const DRAG = Symbol('DRAG')

export default (state, action) => {

  if (action.type === BEGIN_DRAG) {

    let { position } = action

    return {
      ...state,
      positioningStartPoint: position,
    }

  }


  if (action.type === DRAG) {

    const new_state = computed_drag(state, action)

    return new_state

  }

  if (action.type === END_DRAG) {

    let { appId, list, map, typeIndex, positioningObjects, selection } = state

    if (positioningObjects) {
      const ids = Object.keys(positioningObjects)
      let paths = removeChildren(ids.map(id => map[id]))

      const componentIds = paths.map(
        path => getObject(list, subPath(path, 1))?.id
      )

      paths = paths.filter(path => pathLength(path) > 1)
      let reducedIds = paths.map(path => getObject(list, path).id)

      for (let id of reducedIds) {
        let path = map[id]
        let object = getObject(list, path)
        let absoluteBbox = getAbsoluteBbox(object, list, map)

        let newParentId = getParentId(list, map, typeIndex, {
          ...absoluteBbox,
          id,
        })

        if (!newParentId) {
          list = remove(list, path)
          map = remapSiblings(list, map, path)
          selection = selection.filter(id => id !== object.id)
        } else {

          let newComponent = getObject(list, subPath(map[newParentId], 1))
          let oldComponent = getObject(list, subPath(path, 1))

          let xDiff = newComponent.x - oldComponent.x
          let yDiff = newComponent.y - oldComponent.y

          let currentParentIds = getParentIds(list, map, object.id)
          let currentParentId = currentParentIds.slice(-1)[0] || null

          if (newParentId !== currentParentId) {

            let newObject = deepMap([object], obj => ({
              ...obj,
              x: obj.x - xDiff,
              y: obj.y - yDiff,
            }))[0]

            list = update(list, path, newObject)

            let [newList, newMap] = changeObjectParent(
              list,
              map,
              object.id,
              newParentId
            )

            list = newList
            map = newMap
          }
        }
      }

      saveTouched(
        appId,
        list,
        map,
        Object.keys(positioningObjects).concat(componentIds)
      )
    }

    return {
      ...state,
      map,
      list,
      selection: [...selection],
      positioningObjects: null,
      positioningStartPoint: null,
    }
  }

  return state
}


// Actions
export const beginDrag = position => ({
  type: BEGIN_DRAG,
  position,
})

export const drag = (position, shiftPressed, altPressed) => ({
  type: DRAG,
  position,
  shiftPressed,
  altPressed,
})

export const endDrag = () => ({
  type: END_DRAG,
})


// Selectors
export const getXGrid = state => {
  return state.editor.objects.present.xGrid
}

export const getYGrid = state => {
  return state.editor.objects.present.yGrid
}

export const getDragging = state => {
  return !!state.editor.objects.present.positioningStartPoint
}
