import React, {Component} from 'react'
import classNames from 'classnames'

import Icon from '../../Shared/Icon'
import 'styles/ObjectAlignControl.css'
import InspectRow from './Row'


export default class ObjectAlignControl extends Component {

  handleChange = newValue => () => {

    let { name, onChange } = this.props

    onChange(newValue)

  }

  render() {
    
    let { value } = this.props

    return (

        <InspectRow labeled bindable title="Выравнивание">

            <div className="object-align-control">
                <ObjectAlignButton
                    direction="left"
                    value={value}
                    onChange={this.handleChange('left')}
                />
                <ObjectAlignButton
                    direction="horizontal-center"
                    value={value}
                    onChange={this.handleChange('horizontal-center')}
                />
                <ObjectAlignButton
                    direction="right"
                    value={value}
                    onChange={this.handleChange('right')}
                />
                <ObjectAlignButton
                    direction="top"
                    value={value}
                    onChange={this.handleChange('top')}
                />
                <ObjectAlignButton
                    direction="vertical-center"
                    value={value}
                    onChange={this.handleChange('vertical-center')}
                />
                <ObjectAlignButton
                    direction="bottom"
                    value={value}
                    onChange={this.handleChange('bottom')}
                />
            </div>
        </InspectRow>


    )
  }
}

const ObjectAlignButton = ({ direction, value, onChange }) => (
  <span
    onClick={onChange}
    className={classNames('object-align-item', { selected: value === direction })}
  >
    <Icon type={`align-parent-${direction}`} />
  </span>
)
