import React, { Component } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import 'styles/SelectControl.css';

export default class SelectControl extends Component {

  static defaultProps = {
    small: true,
  }

  handleChange = (selectedOption) => {

    const { name, onChange } = this.props

    onChange({ [name]: selectedOption })
    
  }

  render() {

    const { options, value, defaultValue, placeholder, label, small } = this.props

    // Устанавливаем выбранное значение
    const selectedValue = value !== undefined ? value : defaultValue

    return (
      <div className="select-control">
        
        <Select
          classNamePrefix="wrapped-select"
          className={classNames(small && 'small')}
          options={options}
          onChange={this.handleChange}
          value={selectedValue}
          placeholder={placeholder}
        />

        <label>{label}</label>

      </div>
    )

  }
}