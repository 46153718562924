import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import { getCursorClass } from 'utils/tools'

import { getApp, canEditDraft, getAuthComponentId } from "ducks/apps/selectors";
import {getLoading, getPanning, getSelectedIds, getZoom, selectObjects, setZoom,} from 'ducks/editor/objects'
import {getActiveTool} from 'ducks/editor/tools'
import { drag, endDrag, getDragging} from 'ducks/editor/positioning'
import {getEditingShape} from 'ducks/editor/shapeEditing'
import {getShowConnections} from 'ducks/settings'

import KeyboardEvents from './KeyboardEvents'
import CanvasBackdrop from './Backdrop'
import BoundingBoxes from './BoundingBoxes'
import HoverSelection from './HoverSelection'
import CanvasLoader from './Loader'
import Screen from './Screen'

import CreateObject from './CreateObject'
import EmptyCanvas from './Empty'

import SnapGrid from './SnapGrid'
import Positioning from './Positioning'

import 'styles/Canvas.css'
import CompoundShapeEditor from "./CompoundShapeEditor";
import {defaultBranding} from "../../../utils/colors";
import {getBoundingBox} from "../../../common/utils";
import Zoom from "./Zoom";
import {isAdmin} from "../../../ducks/users";

class Canvas extends Component {

  constructor(props) {

    super(props)

    this.state = {
      activeZoom: null,
      trackVertHeight: 0,
      downY: false,
      downX: false,
      //yogaReady: false,
    }

    this.canvasRef = React.createRef()


    this._zoomTimeout     = null
    this._prevEventScale  = null
    this._animationFrame  = null

  }

  static childContextTypes = {
    editable: PropTypes.bool,
    getSelection: PropTypes.func,
  }

  getChildContext() {
    return {
      editable: this.canEdit(),
      getSelection: this.getSelection,
    }
  }

  canEdit = () => {
    let {canEdit, isAdmin} = this.props

    return canEdit || isAdmin
  }

  getSelection = () => {
    let {selection} = this.props

    return selection
  }

  saveZoom = () => {
    let {setZoom} = this.props
    let {activeZoom} = this.state
    let outerActiveZoom = activeZoom

    if (!activeZoom) {
      return
    }

    setZoom(activeZoom.scale, activeZoom.offset)

    window.setTimeout(() => {

      let {activeZoom} = this.state

      if (activeZoom !== outerActiveZoom) {
        return
      }

      this.setState({activeZoom: null})

    }, 10)
  }

  getZoom = () => {

    let {zoom} = this.props

    let {activeZoom} = this.state

    return activeZoom || zoom

  }

  setZoom = (scale, offset) => {
    
    window.clearTimeout(this._zoomTimeout)

    this._zoomTimeout = window.setTimeout(this.saveZoom, 100)

    let activeZoom = { scale, offset }

    this.setState({ activeZoom })
  }

  getOffset = (scale, center, prevScale, prevOffset) => {

    let [offsetX, offsetY] = prevOffset
    let [centerX, centerY] = center

    let deltaScale = scale / prevScale
    let newOffsetX = offsetX - (centerX - offsetX) * (deltaScale - 1)
    let newOffsetY = offsetY - (centerY - offsetY) * (deltaScale - 1)

    return [newOffsetX, newOffsetY]
  }

  handleWheel = (e) => {


    e.stopPropagation(); e.preventDefault()


    let { objects } = this.props

    let zoom = this.getZoom();

    if (objects.length === 0) {
      return
    }

    let prevScale = zoom.scale
    let prevOffset = zoom.offset

    let [offsetX, offsetY] = prevOffset

    // ZOOM
    if (e.ctrlKey || 'scale' in e) {

      let scale

      if ('scale' in e) {

        scale = prevScale * (e.scale / (this._prevEventScale || 1))

        this._prevEventScale = e.scale

      } else {

        let zoomIntensity = 0.001 // Adjust this value for zoom speed

        scale = prevScale * (1 - zoomIntensity * e.deltaY)

      }

      // Clamp the scale value
      scale = Math.max(Math.min(scale, 64), 1 / 16)

      let center = [e.clientX, e.clientY]

      let offset = this.getOffset(scale, center, prevScale, prevOffset)
      
      this.setZoom(scale, offset)

    } else {

      // PAN
      let panSpeed = 1; // Adjust this value for pan speed
      let newOffset = [offsetX - e.deltaX * panSpeed, offsetY - e.deltaY * panSpeed];

      this.setZoom(zoom.scale, newOffset);

    }

  }

  handleMouseDown = e => {

    e.stopPropagation()
    e.preventDefault()

    this.setState({downY: true})


  }

  handleMouseUp = e => {
    this.setState({downY: false, downX: false})
  }

  handleMouseMove = e => {

    e.stopPropagation()
    e.preventDefault()
    let { downY } = this.state

    let { objects } = this.props
    if (objects.length === 0 || !downY) {
      return
    }
    let zoom = this.getZoom()



    let prevScale = zoom.scale

    let prevOffset = zoom.offset
    let [offsetX, offsetY] = prevOffset
    let {movementX, movementY} = e

    let newOffset = [offsetX, offsetY + movementY]

    this.setZoom(prevScale, newOffset)


  }

  handlePanning = e => {

    e.stopPropagation()

    e.preventDefault()

    let {objects} = this.props
    let zoom = this.getZoom()

    if (objects.length === 0) return null

    let prevOffset = zoom.offset
    let [offsetX, offsetY] = prevOffset
    let {movementX, movementY} = e

    let newOffset = [offsetX + movementX, offsetY + movementY]

    return this.setZoom(zoom.scale, newOffset)
  }

  handleGestureStart = e => {

    e.preventDefault()

    this._prevEventScale = 1

  }

  componentDidMount() {

    document.addEventListener('gesturestart', this.handleGestureStart)

    document.addEventListener('gesturechange', this.handleWheel)

    // this.canvasRef.current.addEventListener('wheel', this.handleWheel)
    this.canvasRef.current.addEventListener('wheel', this.handleWheel, { passive: false });

    this.canvasRef.current.addEventListener('mouseup', this.handleMouseUp)

    document.addEventListener('mouseup', this.handleMouseUp)

  }

  componentWillUnmount() {

    document.removeEventListener('gesturestart', this.handleGestureStart)

    document.removeEventListener('gesturechange', this.handleWheel)

    this.canvasRef.current.removeEventListener('wheel', this.handleWheel)

    this.canvasRef.current.removeEventListener('mouseup', this.handleMouseUp)

    document.removeEventListener('mouseup', this.handleMouseUp)
  }

  renderContent = () => {
    
    let {
      activeTool,
      positioningObjects,
      loading,
      objects,
      editingShape,
      authComponentId,
      branding,
      drag,
      endDrag,
      launchComponentId,
      isPanning,
      showConnections,
      selection,
      app
    } = this.props


    let {activeZoom} = this.state

    let zoom = this.getZoom()

    authComponentId = authComponentId || launchComponentId

    if ((loading && objects.length === 0)) {
      return <CanvasLoader/>
    }

    let depthOffset = 0

    return (
      <>

        {positioningObjects ? (
          <Positioning drag={drag} endDrag={endDrag}/>
        ) : null}

        <KeyboardEvents />

        <CanvasBackdrop/>
        
        {objects.length === 0 ? <EmptyCanvas/> : null}

        {objects?.map(obj => (
          <Screen
            key={`screen-${obj.id}`}
            component={obj}
            zoom={zoom}
            editable={false}
            zoomActive={!!activeZoom}
            branding={branding}
          />
        ))}

        <Zoom/>

        {activeZoom ? null : (
          <>
            <svg className="bounding-boxes" width="100%" height="100%">
              <SnapGrid zoom={zoom}/>
              <BoundingBoxes/>
              <HoverSelection/>
              {activeTool && <CreateObject tool={activeTool} zoom={zoom}/>}
              {!activeTool && editingShape && <CompoundShapeEditor zoom={zoom}/>}
            </svg>

          </>
        )}

      </>
    )
  }

  render() {

    let {activeTool} = this.props

    let cursorClass = getCursorClass(activeTool)

    const verHolderStyle = {
      position: "absolute",
      right: 0,
      top: 0,
      height: window.innerHeight,
      width: 8
    };

    let verSliderStyle = {
      right: 0,
      top: 0,
      height: window.innerHeight,
      background: "#000",
      width: 8
    };

    let { objects } = this.props

    let zoom = this.getZoom()

    if (objects.length > 0) {
      const bounding = getBoundingBox(objects)


      verSliderStyle = {
        ...verSliderStyle,
        height:bounding.height*zoom.scale  ,
        transform: `translateY(${zoom.offset[1] - bounding.height * zoom.scale }px)`
      }
      
    }

    return (
      <div>
        <div className={classNames('canvas', cursorClass)} ref={this.canvasRef}>

          {this.renderContent()}

          <div className="vertical-scroll-holder" style={verHolderStyle}>
            <div
                className="vertical-scroll-slider"
                style={verSliderStyle}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}

            />
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state, {appId}) => {

  const app = getApp(state, appId)

  return {
    app: app,
    canEdit: canEditDraft(state, appId),
    isAdmin: isAdmin(state),
    branding: defaultBranding,
    positioningObjects: getDragging(state),
    editingShape: getEditingShape(state),
    activeTool: getActiveTool(state),
    objects: selectObjects(state),
    selection: getSelectedIds(state),
    zoom: getZoom(state),
    loading: getLoading(state),
    authComponentId: getAuthComponentId(state, appId),
    isPanning: getPanning(state),
    showConnections: getShowConnections(state),
  }
}

export default connect(mapStateToProps, {
  setZoom,
  drag,
  endDrag,
})(Canvas)