import React, {Component} from 'react'
import classNames from 'classnames'

import Icon from '../../Shared/Icon'
import 'styles/ObjectAlignControl.css'
import InspectRow from './Row'

export default class FlipControl extends Component {
  handleChange = newValue => () => {
    let { name, onChange } = this.props

    onChange(newValue)
  }

  render() {
    let { value } = this.props


    return (
        <InspectRow labeled bindable title="Преобразования">


            <div className="object-align-control">
                <FlipButton
                    direction="x"
                    value={value}
                    onChange={this.handleChange('x')}
                />
                <FlipButton
                    direction="y"
                    value={value}
                    onChange={this.handleChange('y')}
                />

            </div>
        </InspectRow>


    )
  }
}

const FlipButton = ({ direction, value, onChange }) => (
  <span
    onClick={onChange}
    className={classNames('object-align-item', { selected: value === direction })}
  >


    <Icon type={`flip-${direction}`} />
  </span>
)
