import update from 'immutability-helper'

import {getObject, getObjectId, subPath, uniqueElements} from 'common/utils'

import {getDoubleClickSelection, getSelectableObject,} from '../../utils/selection'

import {getSnapGrid} from '../../utils/snapping'

export const DEFAULT_SELECTION = []

const SET_SELECTION         = Symbol('SET_SELECTION')
const SET_SELECTION_DIVIDER = Symbol('SET_SELECTION_DIVIDER')
const SET_EXPAND_SELECTION  = Symbol('SET_EXPAND_SELECTION')
const SET_LAYERS_HOVER      = Symbol('SET_LAYERS_HOVER')
const SET_CANVAS_HOVER      = Symbol('SET_CANVAS_HOVER')
const SET_DIVIDER_HOVER     = Symbol('SET_DIVIDER_HOVER')
const RECALCULATE_SNAP_GRID = Symbol('RECALCULATE_SNAP_GRID')
export const SELECT_PARENT  = Symbol('SELECT_PARENT')

export const getActiveComponent = (list, map, objectIds) => {
  let id = objectIds[0]

  if (id) {
    let screen = getObject(list, subPath(map[id], 1))

    return screen.id
  }

  return null
}

export default (state, action) => {

  if (action.type === SET_SELECTION) {

    let { groupsFirst, object, shiftKey } = action
    let { selection } = state

    if (Array.isArray(object)) {
      
      if (shiftKey) {
        return {
          ...state,
          selectedParent: null,
          selection: uniqueElements(selection.concat(object)),
        }
      }

      return {
        ...state,
        selectedParent: null,
        selection: object,
      }

    } else if (groupsFirst) {
      let selectionPaths = selection.map(id => state.map[id])
      let path = state.map[object]
      let newPath = getSelectableObject(selectionPaths, path, 2)
      object = getObject(state.list, newPath)?.id
    }

    let index = selection.indexOf(object)

    if (shiftKey) {

      if (object && index >= 0 && selection.length > 1) {
        selection = update(selection, { $splice: [[index, 1]] })
      } else if (object && index === -1) {
        selection = selection.concat([object])
      }

    } else if (index === -1) {
      if (object) {
        selection = [object]
      } else {
        selection = DEFAULT_SELECTION
      }
    }

    return {
      ...state,
      selectedParent: null,
      selection,
      hoverSelection: DEFAULT_SELECTION,
      activeComponent: getActiveComponent(state.list, state.map, selection),
    }

  }

  if (action.type === SET_SELECTION_DIVIDER) {
    let {  object} = action

    return {
      ...state,
      selectedDivider: object && object.id,
    }
  }

  if (action.type === SET_EXPAND_SELECTION) {
    let selectionPaths = state.selection.map(id => state.map[id])
    let path = state.map[action.objectId]
    let selectionPath = getDoubleClickSelection(selectionPaths, path)
    let selection = [getObjectId(state.list, selectionPath)]

    return {
      ...state,
      selection,
      selectedParent: null,
      hoverSelection: DEFAULT_SELECTION,
    }
  }


  if (action.type === SET_LAYERS_HOVER || action.type === SET_CANVAS_HOVER) {

    let { object } = action;

    let { positioningObjects } = state;
  
    if (positioningObjects) {
      return state;
    }
  
    let hover = [object];
  
    if (object && action.type === SET_CANVAS_HOVER) {

      let selectionPaths  = state.selection.map(id => state.map[id])
      let path            = state.map[object]
      let newPath         = getSelectableObject(selectionPaths, path, 2)
      let newObject       = getObject(state.list, newPath)
      hover               = [newObject.id]

      // // Проверяем, создан ли новый объект (например, группа)
      // if (false) {
      //   return {
      //     ...state,
      //     hoverSelection: hover,
      //     selection: [newObject.id], // Обновляем выбор
      //     activeComponent: getActiveComponent(state.list, state.map, [newObject.id]),
      //     // Дополнительные обновления состояния, если необходимо
      //   };
      // }
      
    }
  
    if (!object) {
      hover = DEFAULT_SELECTION;
    }

    return {
      ...state,
      hoverSelection: hover,
    }
    
  }

  // if (action.type === SET_LAYERS_HOVER || action.type === SET_CANVAS_HOVER) {


  //   let { object } = action

  //   let { positioningObjects } = state

  //   if (positioningObjects) {
  //     return
  //   }


  //   let hover = [object]

  //   if (object && action.type === SET_CANVAS_HOVER) {

  //     let selection = state.selection.map(id => state.map[id])
  //     let path      = state.map[object]
  //     let newPath   = getSelectableObject(selection, path, 2)
  //     let o         = getObject(state.list, newPath)
  //     hover         = [getObject(state.list, newPath).id]

  //   }

  //   if (!object) {
  //     hover = DEFAULT_SELECTION
  //   }

  //   return {
  //     ...state,
  //     hoverSelection: hover,
  //   }

  // }




  if (action.type === SET_DIVIDER_HOVER) {
    let { object } = action
    return {
      ...state,
      hoverDivider: object && object.id,
    }
  }

  if (action.type === RECALCULATE_SNAP_GRID) {
    return {
      ...state,
      ...getSnapGrid(state, state.selection),
    }
  }

  if (action.type === SELECT_PARENT) {
    const { objectId } = action

    return {
      ...state,
      selectedParent: objectId,
    }
  }

  return state
}

export const setSelection = (object, shiftKey, groupsFirst = false) => {
  return {
    type: SET_SELECTION,
    groupsFirst,
    object,
    shiftKey,
  }
}

export const setSelectionDivider = (object) => {
  return {
    type: SET_SELECTION_DIVIDER,
    object,
  }
}
export const resetSelection = () => {
  return {
    type: SET_SELECTION,
    object: DEFAULT_SELECTION,
  }
}

export const setExpandSelection = objectId => ({
  type: SET_EXPAND_SELECTION,
  objectId,
})

export const setLayersHover = object => ({
  type: SET_LAYERS_HOVER,
  object,
})

export const setCanvasHover = object => ({
  type: SET_CANVAS_HOVER,
  object,
})
export const setDividerHover = object => ({
  type: SET_DIVIDER_HOVER,
  object,
})
export const recalculateSnapGrid = () => ({
  type: RECALCULATE_SNAP_GRID,
})

// SELECTORS

export const getSelection = ({ editor }) => editor.objects.present.selection

export const getHoverSelection = ({ editor }) => {
  return editor.objects.present.hoverSelection
}

export const getCanvasHover = ({ editor }) => {
  return editor.objects.present.hoverSelection
}
