import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'

import {COMPONENT, COMPONENT_INSTANCE, FORM, GROUP, LABEL, LIBRARY_COMPONENT, LINE,} from 'common/constants'

import {scale, scaleValue} from '../../../../utils/zoom'
import {getArrowPath, getPathData} from '../../../../utils/arrows'
import {getAppComponent} from '../../../../utils/libraries'
import {getSnapValue} from '../../../../utils/snapping'
import {getComponent, getCurrentAppId} from '../../../../ducks/editor/objects'
import { getApp } from "ducks/apps/selectors";
import ResizeHandle from './ResizeHandle'
import Rule from './Rule'

const LEFT = 'LEFT'
const RIGHT = 'RIGHT'
const TOP = 'TOP'
const BOTTOM = 'BOTTOM'

class BoundingBox extends Component {

  static defaultProps = { resize: true }

  state = {
    activeResize: null,
    resizeStartPoint: null,
    prevState: null,
  }

  resize = (...directions) => e => {

    e.stopPropagation()

    let { object } = this.props
    let activeResize = {}
    let { x, y, angle,  width, height } = object
    let prevState = { x, y, angle, width, height }
    let resizeStartPoint = [e.clientX, e.clientY]

    directions.forEach(k => (activeResize[k] = true))

    this.setState({
      activeResize,
      prevState,
      resizeStartPoint,
    })

  }

  handleMouseMove = e => {

    let { prevState, resizeStartPoint, activeResize } = this.state
    let { onChange, zoom } = this.props

    if (activeResize) {

      let [startX, startY] = resizeStartPoint
      
      let newX = e.clientX
      let newY = e.clientY

      let xSign = 1
      let ySign = 1

      if (activeResize.LEFT) {
        xSign = -1
      }

      if (activeResize.TOP) {
        ySign = -1
      }

      let diffX =
        activeResize.LEFT || activeResize.RIGHT
          ? Math.round((newX - startX) / zoom.scale)
          : 0

      let diffY =
        activeResize.TOP || activeResize.BOTTOM
          ? Math.round((newY - startY) / zoom.scale)
          : 0

      let newWidth = Math.max(0, +prevState.width + diffX * xSign)
      let newHeight = Math.max(0, +prevState.height + diffY * ySign)

      if(newWidth < 100 || newHeight < 100){
        return
      }

      let aspectRatio = null

      if (e.shiftKey && this.verticalResizeable()) {
        let ratios = []
        aspectRatio = prevState.height / prevState.width

        if (activeResize.LEFT || activeResize.RIGHT) {
          ratios.push(newWidth / prevState.width)
        }

        if (activeResize.TOP || activeResize.BOTTOM) {
          ratios.push(newHeight / prevState.height)
        }

        let ratio = Math.max(0, Math.min(...ratios))

        newWidth = prevState.width * ratio
        newHeight = prevState.height * ratio
      }

      let xEffect = 0.5
      let yEffect = 0.5

      if (!e.altKey) {
        if (activeResize.TOP) {
          yEffect = 1
        }

        if (activeResize.BOTTOM) {
          yEffect = 0
        }

        if (activeResize.LEFT) {
          xEffect = 1
        }

        if (activeResize.RIGHT) {
          xEffect = 0
        }
      }

      let changeObj = {
        x: prevState.x + xEffect * (prevState.width - newWidth),
        width: newWidth,
        activeResize: activeResize
      }

      if (this.verticalResizeable()) {
        changeObj.y = prevState.y + yEffect * (prevState.height - newHeight)
        changeObj.height = newHeight
      }

      if (activeResize.LEFT || activeResize.RIGHT) {
        changeObj = this.snapXChanges(changeObj, xEffect, aspectRatio, yEffect)
      }

      if ((this.verticalResizeable() && activeResize.TOP) || activeResize.BOTTOM) {
        changeObj = this.snapYChanges(changeObj, yEffect, aspectRatio, xEffect)
      }

      onChange(changeObj)
    }


  }


  snapXChanges = (changeObj, xEffect, aspectRatio = null, yEffect) => {
    let { app, object, xGrid, setXSnap, zoom, component } = this.props;
  
    const template_width = app.width;
    const template_height = app.height;
  
    let baseX = 0;
    let baseY = 0;
  
    if (object.type !== COMPONENT) {
      baseX = component.x;
      baseY = component.y;
    }
  
    let coords = {};
  
    if (xEffect !== 0) {
      coords.left = changeObj.x + baseX;
    }
  
    if (xEffect !== 1) {
      coords.right = changeObj.x + changeObj.width + baseX;
    }
  
    if (xEffect !== 0.5) {
      coords.center = changeObj.x + changeObj.width / 2 + baseX;
    }
  
    if (Object.keys(coords).length === 0) {
      return changeObj;
    }
  
    let snapResult = getSnapValue(xGrid, coords, zoom, true);
  
    if (!snapResult) {
      setXSnap(null);
    } else {
      let key = Object.keys(snapResult)[0];
      let value = snapResult[key];
  
      setXSnap(value);
  
      let diff = value - coords[key];
  
      let centered = xEffect === 0.5;
  
      changeObj = { ...changeObj };
  
      if (key === 'left') {
        changeObj.x = value - baseX;
        changeObj.width += centered ? 2 * -diff : -diff;
      }
  
      if (key === 'right') {
        changeObj.x += centered ? -diff : 0;
        changeObj.width += centered ? 2 * diff : diff;
      }
  
      if (key === 'center') {
        if (xEffect === 0) {
          changeObj.width += 2 * diff;
        } else if (xEffect === 1) {
          changeObj.x += 2 * diff;
          changeObj.width -= 2 * diff;
        }
      }
    }
  
    if (aspectRatio !== null) {
      let newHeight = changeObj.width * aspectRatio;
      let yDiff = -yEffect * (newHeight - changeObj.height);
  
      changeObj.height = newHeight;
      changeObj.y += yDiff;
    }
  
    // Constrain x within template boundaries
    changeObj.x = Math.max(0, Math.min(template_width - changeObj.width, changeObj.x));
  
    // Constrain y within template boundaries
    changeObj.y = Math.max(0, Math.min(template_height - changeObj.height, changeObj.y));
  
    // Ensure width does not exceed template boundaries
    changeObj.width = Math.min(changeObj.width, template_width - changeObj.x);
  
    // Ensure height does not exceed template boundaries
    changeObj.height = Math.min(changeObj.height, template_height - changeObj.y);
  
    return changeObj;
  };
  

  snapYChanges = (changeObj, yEffect, aspectRatio = null, xEffect) => {
    let { app, object, yGrid, setYSnap, zoom, component } = this.props;
  
    const template_width = app.width;
    const template_height = app.height;
  
    let baseX = 0;
    let baseY = 0;
  
    if (object.type !== COMPONENT) {
      baseX = component.x;
      baseY = component.y;
    }
  
    let coords = {};
  
    if (yEffect !== 0) {
      coords.top = changeObj.y + baseY;
    }
  
    if (yEffect !== 1) {
      coords.bottom = changeObj.y + changeObj.height + baseY;
    }
  
    if (yEffect !== 0.5) {
      coords.center = changeObj.y + changeObj.height / 2 + baseY;
    }
  
    if (Object.keys(coords).length === 0) {
      return changeObj;
    }
  
    let snapResult = getSnapValue(yGrid, coords, zoom, true);
  
    if (!snapResult) {
      setYSnap(null);
    } else {
      let key = Object.keys(snapResult)[0];
      let value = snapResult[key];
  
      setYSnap(value);
  
      let diff = value - coords[key];
      let centered = yEffect === 0.5;
      changeObj = { ...changeObj };
  
      if (key === 'top') {
        changeObj.y = value - baseY;
        changeObj.height += centered ? 2 * -diff : -diff;
      }
  
      if (key === 'bottom') {
        changeObj.y += centered ? -diff : 0;
        changeObj.height += centered ? 2 * diff : diff;
      }
  
      if (key === 'center') {
        if (yEffect === 0) {
          changeObj.height += 2 * diff;
        } else if (yEffect === 1) {
          changeObj.y += 2 * diff;
          changeObj.height -= 2 * diff;
        }
      }
    }
  
    if (aspectRatio !== null) {
      let newWidth = changeObj.height / aspectRatio;
      let xDiff = -xEffect * (newWidth - changeObj.width);
  
      changeObj.width = newWidth;
      changeObj.x += xDiff;
  
      // Apply horizontal constraints due to aspect ratio change
      changeObj.x = Math.max(0, Math.min(template_width - changeObj.width, changeObj.x));
      changeObj.width = Math.min(changeObj.width, template_width - changeObj.x);
    }
  
    // Constrain y within template boundaries
    changeObj.y = Math.max(0, Math.min(template_height - changeObj.height, changeObj.y));
  
    // Ensure height does not exceed template boundaries
    changeObj.height = Math.min(changeObj.height, template_height - changeObj.y);
  
    return changeObj;
  };
  

  
  handleMouseUp = () => {
    let { activeResize } = this.state
    let { onResizeEnd, resetSnaps } = this.props

    if (activeResize) {
      resetSnaps()
      this.setState({ activeResize: null })

      if (onResizeEnd) {
        onResizeEnd()
      }
    }
  }

  verticalResizeable = () => {
    let { app, object } = this.props

    if (object.type === LIBRARY_COMPONENT || object.type === LINE) {
      let { libraryName, componentName } = object
      let componentConfig = getAppComponent(app, libraryName, componentName)

      return !!(componentConfig && componentConfig.resizeY)
    }

    return ![LABEL, COMPONENT_INSTANCE, FORM, GROUP].includes(object.type)
  }

  horizontalResizeable = () => {
    let { object, app } = this.props

    if (object.type === LIBRARY_COMPONENT) {
      let { libraryName, componentName } = object

      let componentConfig =
        getAppComponent(app, libraryName, componentName) || {}

      if ('resizeX' in componentConfig) {
        return !!componentConfig.resizeX
      }
    }

    return object.type !== GROUP
  }

  renderLink = () => {
    let { object, link, linkTargets, zoom } = this.props

    if (!link || !linkTargets) {
      return null
    }

    let { width, height } = object
    let absoluteObject = { ...object.absolutePosition, width, height }
    let target = linkTargets.filter(screen => screen.id === link.target)[0]
    let path = getPathData(absoluteObject, target, zoom)
    let arrow = getArrowPath(absoluteObject, target, zoom)

    return (
      <g>
        <path className="link-path" d={path} />
        <path className="link-arrow" d={arrow} />
      </g>
    )
  }

  render() {
    let { object, zoom, resize, link } = this.props
    let { activeDrag } = this.state
    let verticalResizeable = this.verticalResizeable()


    let { x, y } = object.absolutePosition
    let [xScaled, yScaled] = scale([x, y], zoom)

    let x2Scaled =
      Math.round(xScaled + scaleValue(object.width, zoom) + 0.5) - 0.5

    let y2Scaled =
      Math.round(yScaled + scaleValue(object.height, zoom) + 0.5) - 0.5

    xScaled = Math.round(xScaled + 0.5) - 0.5
    yScaled = Math.round(yScaled + 0.5) - 0.5

    let widthScaled = x2Scaled - xScaled || 0
    let heightScaled = y2Scaled - yScaled || 0

    let showHandles = !!resize

    if (!this.horizontalResizeable()) {
      showHandles = false
    }

    const transform = /*object.angle ? `rotate(${object.angle} ${widthScaled / 2} ${heightScaled / 2})` :*/ ''
    return (
      <React.Fragment>

        {this.renderLink()}

        <g
          className={classNames('bounding-box', { 'has-link': link })}
          transform={`translate(${xScaled}, ${yScaled})`}
        >

          <DocumentEvents
            enabled={activeDrag}
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
          />

          <rect
            x={-0}
            y={-0}
            width={widthScaled}
            height={heightScaled}
            transform = {transform}
            className="selection-box"
          />

          {<Rule
            position="top"
            heightScaled={heightScaled}
            widthScaled={widthScaled}
            height={object.height}
            width={object.width}
            depth={object.depth}
          />}

          {showHandles && (
            <g className="resize-handles" transform={transform}>
              {widthScaled > 20 && verticalResizeable ? (
                <>
                  <ResizeHandle
                    invisible
                    onMouseDown={this.resize(TOP)}
                    cursor="ns"
                    width={widthScaled}
                    position="top"
                  />
                  <ResizeHandle
                    invisible
                    onMouseDown={this.resize(BOTTOM)}
                    cursor="ns"
                    width={widthScaled}
                    y={heightScaled}
                    position="bottom"
                  />
                </>
              ) : null}

              {heightScaled > 20 || !verticalResizeable ? (
                <>
                  <ResizeHandle
                    invisible={verticalResizeable}
                    onMouseDown={this.resize(LEFT)}
                    cursor="ew"
                    height={heightScaled}
                    position="left"
                  />

                  <ResizeHandle
                    invisible={verticalResizeable}
                    onMouseDown={this.resize(RIGHT)}
                    cursor="ew"
                    height={heightScaled}
                    x={widthScaled}
                    position="right"
                  />

                </>
              ) : null}

              {!verticalResizeable ? null : (
                <React.Fragment>

                  <ResizeHandle
                    onMouseDown={this.resize(LEFT, TOP)}
                    cursor="nwse"
                  />

                  <ResizeHandle
                    onMouseDown={this.resize(RIGHT, TOP)}
                    cursor="nesw"
                    x={widthScaled}
                  />

                  <ResizeHandle
                    onMouseDown={this.resize(LEFT, BOTTOM)}
                    cursor="nesw"
                    y={heightScaled}
                  />

                  <ResizeHandle
                    onMouseDown={this.resize(RIGHT, BOTTOM)}
                    cursor="nwse"
                    x={widthScaled}
                    y={heightScaled}
                  />

                </React.Fragment>
              )}
            </g>
          )}
        </g>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, { object }) => ({
  app: getApp(state, getCurrentAppId(state)),
  component: getComponent(state, object.id),
})

export default connect(mapStateToProps)(BoundingBox)
